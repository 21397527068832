import React from 'react';

import Bloco from './Bloco';

function Rodape() {
    return <>
        <div className="bg-color2">
            <div className="container" style={{ color: '#cdcdcd', paddingTop: '80px', paddingBottom: '80px', verticalAlign: 'middle', fontSize: '18px' }}>
                <Bloco id="2" pagina="N"/>
            </div>
        </div>
    </>
}

export default Rodape;