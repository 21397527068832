import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import Rodape from '../../componentes/Rodape';
import IfComponent from '../../componentes/IfComponent';

function Shows() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ pesquisa, setPesquisa ] = useState({
        campo: 'SHO_DESCRICAO',
        texto: ''
    });
    const [ operacao, setOperacao ] = useState('listar');

    const [ show, setShow ] = useState({
        SHO_CODIGO: '',
        SHO_DATA: '0',
        SHO_DESCRICAO: '',
        SHO_HORA: '0',
        SHO_PUBLICO: 'S',
        SHO_STATUS: 'A',
        SHO_VALOR: '0',
    });

    const SelectItemsTIPO = [
        {value: "SHO_CODIGO", label: "Código" },
        {value: "SHO_DESCRICAO", label: "Descricao" }
    ];

    const SelectItemsSIMNAO = [
        {value: "S", label: "SIM" },
        {value: "N", label: "NÃO" }
    ];

    const SelectItemsSTATUS = [
        {value: "A", label: "A REALIZAR" },
        {value: "R", label: "REALIZADO" },
        {value: "C", label: "CANCELADO" }
    ];


    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
                formData.set('op', 'listar');
                formData.set('pagina', novaPagina);
                formData.set('campo', pesquisa.campo);
                formData.set('pesquisa', pesquisa.texto);
            Api.post('admin/shows.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar) {
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        setOperacao('');

        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listar(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function pesquisar() {
        setPagina(0);
        setFinal(false);
        listar(0,false,true);
    };

    function reset() {
        window.location.reload();
    };
    
    function cancelar() {
        setOperacao('listar');
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="show") {
            setShow({...show, [name]: valor});
        }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
        }
    }

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/shows.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listar(0,false,true);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    function novo() {
        setShow({
            SHO_CODIGO: '',
            SHO_DATA: '',
            SHO_DESCRICAO: '',
            SHO_HORA: '',
            SHO_PUBLICO: 'S',
            SHO_STATUS: 'A',
            SHO_VALOR: '0',
        });
        setOperacao('inserir');
    }

    async function salvar() {
        const formData = new FormData();
        formData.set('op',operacao);
        formData.set('SHO_CODIGO',show.SHO_CODIGO);
        formData.set('SHO_DATA',show.SHO_DATA);
        formData.set('SHO_DESCRICAO',show.SHO_DESCRICAO);
        formData.set('SHO_HORA',show.SHO_HORA);
        formData.set('SHO_PUBLICO',show.SHO_PUBLICO);
        formData.set('SHO_STATUS',show.SHO_STATUS);
        formData.set('SHO_VALOR',show.SHO_VALOR);
        const response = await Api.post('admin/shows.php',formData);
        if (response.data.erro==='N') {
            let novaListagem = [];
            if (show.SHO_CODIGO===0) { 
                //inserção.
                Swal.fire({title: 'Yessss!', text: 'Show cadastrado com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem.push(...listagem);
                novaListagem.push(response.data.msg);
            } else {
                //edição.
                Swal.fire({title: 'Yessss!', text: 'Show atualizado com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem = listagem.filter(item => (String(item.SHO_CODIGO) !== String(show.SHO_CODIGO)));
                novaListagem.unshift(response.data.msg); //adiciona no inicio da listagem.
            }
            
            //ordena a listagem.
            const novaListagemSort = novaListagem.sort(function(c1,c2) {
                if (c1.SHO_DATA > c2.SHO_DATA) return  1;
                if (c1.SHO_DATA < c2.SHO_DATA) return -1;
                return 0;
            });
            
            setListagem(novaListagemSort);
            setOperacao('listar');
            setShow({        
                SHO_CODIGO: '',
                SHO_DATA: '',
                SHO_DESCRICAO: '',
                SHO_HORA: '',
                SHO_PUBLICO: 'S',
                SHO_STATUS: 'A',
                SHO_VALOR: '0',
            });
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }
    
    function editar(codigo) {
        setOperacao('atualizar');
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', codigo);
        Api.post('admin/shows.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setShow(response.data.msg);
            } else {
                setOperacao('listar');
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH Editar Categoria');
        });        
    }

    //https://bestofreactjs.com/repo/marekrozmus-react-swipeable-list-react-sortable-list
    // const leadingActions = ({id}) => (
    //     <LeadingActions>
    //         <SwipeAction destructive={false} onClick={() => console.info(id, 'clique estrela')}>
    //             <div className="d-flex align-items-center bg-success text-light text-center p-3"><i className="fa fa-fw fa-star text-warning"></i> Estrela</div>
    //         </SwipeAction>     
    //     </LeadingActions>
    // );
    const trailingActions = (item) => (
        <TrailingActions >

            <SwipeAction destructive={false} onClick={() => {editar(item.SHO_CODIGO)}}>
                <div className="d-flex align-items-center bg-primary text-light p-3" style={{cursor: 'pointer', width: '50px'}}><i className="fa fa-fw fa-pencil"></i></div>
            </SwipeAction>

            <SwipeAction destructive={false} onClick={() => {
                Swal.fire({
                    position: 'center',
                    icon: 'question',
                    title: 'Confirma Exclusão?',
                    text: 'Tem certeza que deseja excluir '+item.SHO_DESCRICAO+'?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, quero!',
                    cancelButtonText: 'Nãããoooooo',
                    confirmButtonColor: '#212529'
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log(item);
                        excluir(item);
                    }
                });
            }}>
                <div className="d-flex align-items-center bg-danger text-light text-center p-3" style={{cursor: 'pointer', width: '50px'}}><i className="fa fa-fw fa-trash"></i></div>
            </SwipeAction>
    
            
        </TrailingActions>
    );

    return <>
        <BarraSuperior/>
        <ColunaCentral left={false} right={false}>
            <Cabecalho titulo="Cadastro de Shows" texto="&nbsp;"/>

            <IfComponent condicional={operacao==="inserir" || operacao==="atualizar"}>
                <div className="row mb-3 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="SHO_DATA" value={show.SHO_DATA || ''} onChange={(e) => setCampo(e,'show',false)} mask="99/99/9999"/>
                            <label htmlFor="SHO_DATA">DATA</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="SHO_HORA" value={show.SHO_HORA || ''} onChange={(e) => setCampo(e,'show',false)} mask="99:99"/>
                            <label htmlFor="SHO_HORA">HORA</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="SHO_PUBLICO" value={show.SHO_PUBLICO || ''} options={SelectItemsSIMNAO} onChange={(e) => setCampo(e,'show',false)} placeholder="Selecione"/>
                            <label htmlFor="SHO_PUBLICO">PUBLICO</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="SHO_STATUS" value={show.SHO_STATUS || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e,'show',false)} placeholder="Selecione"/>
                            <label htmlFor="SHO_STATUS">STATUS</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputNumber className="w-100" name="SHO_VALOR" value={show.SHO_VALOR || ''} onValueChange={(e) => setCampo(e,'show',false)} useGrouping={false} minFractionDigits={2} maxFractionDigits={2}/>
                            <label htmlFor="SHO_VALOR">VALOR</label>
                        </span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="SHO_DESCRICAO" value={show.SHO_DESCRICAO || ''} onChange={(e) => setCampo(e,'show',false)} />
                            <label htmlFor="SHO_DESCRICAO">DESCRIÇÃO</label>
                        </span>
                    </div>
                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar()}><i className="fa fas fa-save"></i> Salvar</button>
                    <button className="btn btn-dark" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <div className="row mb-2 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="campo" value={pesquisa.campo || ''} options={SelectItemsTIPO} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                            <label htmlFor="campo">TIPO DE PESQUISA</label>
                        </span>
                    </div>
                    <div className="col-lg-10 col-md-8 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="60" name="texto" value={pesquisa.texto || ''} onChange={(e) => setCampo(e,'pesquisa',false)} />
                            <label htmlFor="texto">PESQUISA</label>
                        </span>
                    </div>
                </div>

                <div className="box shadow-sm d-flex justify-content-between">
                    <button className="btn btn-primary me-2" type="button" name="pesquisar" onClick={ () => pesquisar() }><i className="fa fas fa-search"></i> Pesquisar</button>
                    <button className="btn btn-outline-primary me-2" type="button" name="reset" onClick={ () => reset() }><i className="fa fas fa-refresh"></i> Limpar</button>
                    <button className="btn btn-success ms-auto" type="button" name="novo" onClick={ () => novo() }><i className="fa fas fa-plus"></i> Nova</button>
                </div>

                <IfComponent condicional={ listagem.length > 0}>
                    <div className="container mt-4 p-0"></div>
                    <div className="table-responsive d-none d-sm-block">
                        <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Data/Hora</th>
                                    <th>Descrição</th>
                                    <th style={{ textAlign: 'center'}}>Público</th>
                                    <th style={{ textAlign: 'center'}}>Status</th>
                                    <th style={{ textAlign: 'center'}}>Valor</th>                                
                                    <th style={{ textAlign: 'right'}}>Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {                     
                                    listagem.map((show,idx) => (
                                        <tr key={show.SHO_CODIGO}>
                                            <td>{idx + 1}</td>
                                            <td>{show.SHO_DATA+' - '+show.SHO_HORA}</td>
                                            <td>{show.SHO_DESCRICAO}</td>
                                            <td align="center">{show.SHO_PUBLICO==="S"?<span className="badge rounded-pill bg-success">SIM</span>:<span className="badge rounded-pill bg-danger">NÃO</span>}</td>
                                            <td align="center">{show.SHO_STATUS}</td>
                                            <td align="right">{show.SHO_VALOR}</td>
                                            <td align="right">
                                                <Link to={'/admin/shows/'+show.SHO_CODIGO} title="Setlist"><i className="fa fa-fw fa-music"></i></Link>
                                                <Link to="" onClick={() => editar(show.SHO_CODIGO)} title="Editar"><i className="fa fa-fw fa-pencil"></i></Link>
                                                <Link to="" onClick={() => excluir({ 'codigo': show.SHO_CODIGO, 'nome': show.SHO_DESCRICAO })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                            </td>
                                        </tr>
                                    ))
                                }   
                            </tbody>
                        </table>
                    </div>

                    <div className="container m-0 p-0 g-0 mb-3 d-block d-sm-none">
                        <SwipeableList fullSwipe={false} type={ListType.IOS}>
                            {
                                listagem.map((show,idx) => (
                                    <SwipeableListItem key={show.SHO_CODIGO} trailingActions={trailingActions(show)} >
                                        <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={show.SHO_CODIGO}>
                                            <div className="row g-0">
                                                <div className="col-12 pt-1 px-2 lh-2 text-truncate">
                                                    <Link className="link-white stretched-link" to={'/admin/shows/'+show.SHO_CODIGO} title="Setlist">{show.SHO_DESCRICAO}</Link>
                                                </div>
                                                <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small d-flex justify-content-between">
                                                    <span>{idx+1+' - '}{show.SHO_DATA+' - '+show.SHO_HORA+' - Valor: '+show.SHO_VALOR}</span><span>{show.SHO_PUBLICO==="S"?"PUBLICO":"PRIVADO"} - {show.SHO_STATUS}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwipeableListItem>
                                ))
                            }
                        </SwipeableList>
                    </div>
                </IfComponent>            
            </IfComponent>           

            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-guitar"></i></span><br/>Nenhum show encontrado!</p>
                </div>                    
            </IfComponent>
            <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default Shows;