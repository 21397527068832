import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { InputMask } from 'primereact/inputmask';
import Swal from 'sweetalert2';

//funcoes proprias.
import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import IfComponent from '../../componentes/IfComponent';

function Confirmar() {
    const navigate = useNavigate();
    const { usuarioLogin, usuarioStatus, logout, confirmar } = useContext(AuthContext);
    const [ codigo, setCodigo ] = useState('');
    const [ confirmando, setConfirmando ] = useState(false);
    const [ reenviando, setReenviando ] = useState(false);
    const [ desabilitado, setDesabilitado ] = useState('');
    const [ redirecionar, setRedirecionar ] = useState(false);
  
    async function reenviarCodigo() {
        setDesabilitado('disabled');
        setReenviando(true);
        var formData = new FormData();
        formData.set('op', 'reenviar');
        formData.set('login', usuarioLogin);
        try {
            const response = await Api.post('usuario/profile.php',formData);
            if (response.data.erro==="S") {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            } else {
                Swal.fire({title: 'Confirma lá!', text: response.data.msg, icon: 'info', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch (error) {
            console.log('Erro:',error)
        }
        setReenviando(false);
        setDesabilitado('');
    }

    function confirmarCodigo() {
        setDesabilitado('disabled');
        setConfirmando(true);
        confirmar(codigo);
        setConfirmando(false);
        setDesabilitado('');
        setRedirecionar(true);
    }
    
    function desconectar() {
        logout(false);
        navigate('/');
    }

    useEffect(() => {
        if (redirecionar) {
            if (usuarioStatus==="") {
                navigate('/');
            }
        }
    },[usuarioStatus,navigate,redirecionar]);

    return <>
        <IfComponent condicional={usuarioStatus==="A"}>
            <div className="container-fluid">
                <div className="d-flex align-items-center text-center form-login-container">
                    <div className="form-login w-100 p-0">
                            
                        <img src='/imagens/logo_crazy_maria_branco.svg' style={{maxWidth: '170px'}} alt='Crazy Maria' />

                        <div className="col-12 mt-3">
                            <p className="lead my-5">Sua conta já está confirmada!</p>
                            <Link to='/' className="btn btn-lg btn-success w-100">Ir para página inicial</Link>
                        </div> 
                    </div>
                </div>
            </div>                    
        </IfComponent>

        <IfComponent condicional={usuarioStatus==="I"}>
            <div className="container-fluid">
                <div className="d-flex align-items-center text-center form-login-container">
                    <div className="form-login w-100 p-0">
                            
                        <img src='/imagens/logo_crazy_maria_branco.svg' style={{maxWidth: '170px'}} alt='Crazy Maria' />
        
                        <div className="col-12 mt-3">
                            <p>Digite o código de verificação que enviamos para você por email no campo abaixo.</p>
                        </div>

                        <div className="col-12 mb-3">
                            <InputMask className="w-100 text-center fw-bold p-inputtext-lg" name="confirmacao" value={codigo} onChange={(e) => setCodigo(e.target.value)} mask="999999"/>
                        </div>

                        <div className="col-12 mb-4">
                            <p>Caso ainda não tenha recebido o código, por favor, verifique se não está na caixa de spam no seu aplicativo de emails.</p>
                        </div>

                        <div className="col-12">
                            <input type="hidden" name="op" value="confirmarcodigo" />
                            { confirmando
                                ? <button className={'btn btn-lg btn-success w-100 '+desabilitado}><i className='fa fa-fw fa-spin fa-spinner'></i> Confirmando...</button>
                                : <button className={'btn btn-lg btn-success w-100 '+desabilitado} onClick={() => confirmarCodigo()}>CONFIRMAR</button>
                            }
                            { reenviando
                                ? <button className={'btn w-100 btn-outline-secondary mt-4 '+desabilitado}><i className='fa fa-fw fa-spin fa-spinner'></i> Reenviando Código...</button>
                                : <button className={'btn w-100 btn-outline-secondary mt-4 '+desabilitado} onClick={() => reenviarCodigo()}>Reenviar Código</button>
                            }
                            <button className={'btn w-100 btn-outline-secondary mt-2 '+desabilitado} onClick={() => desconectar()}>Desconectar</button>          
                        </div>

                    </div>
                </div>
            </div>
        </IfComponent>
    </>
}

export default Confirmar;
