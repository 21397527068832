import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from "../imagens/logo_crazy_maria_branco.svg";

function ErrorPage() {
    return <>
        <div className='bg-dark'>
            <div className='container bg-dark'>
                <div className="row d-flex justify-content-center align-items-center text-center vh-100">
                    <div className='col-12' style={{maxWidth: '550px'}}>
                        <Logo height="100"/>
                        <p className="mt-5 lead text-light">Xiiiiii... a página que você tentou acessar não existe aqui.</p>
                        <Link to='/' className="btn btn-lg btn-success w-100 mt-4" type="button">Voltar a página inicial</Link>
                    </div>
                </div>
            </div>    
        </div>    
    </>
}

export default ErrorPage;