import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';

//import Api from '../Api';
import IfComponent from './IfComponent';
import { ReactComponent as Logo } from "../imagens/logo_crazy_maria_branco.svg";
import Perfil from './Perfil';

function MenuMobile(props) {
    //const navigate = useNavigate;
    const { data } = props;
    const [ menus, setMenus ] = useState([]);
    // const location = window.location;
    // const { tipo } = props;

    //var myOffcanvas = document.getElementById('offcanvasRuntime')
    // var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
    
    // function off() {
    //     var offcanvas = document.getElementById('offcanvasRuntime');
    //     var offcanvasBackdrop = document.getElementsByClassName('offcanvas-backdrop');
    //     //console.log('passou aqui', myOffcanvas[0].classList.remove('show'))
    //     offcanvas.classList.remove('show');
    //     offcanvasBackdrop[0].remove();
    //     //navigate('/home');
    //     //myOffcanvas.
    //     //myOffcanvas.classList.remove('show');
    // }


    // function getMenu() {
    //     let cancel = false;

    //     var formData = new FormData();
    //     formData.set('op','menu');
    //     formData.set('tipo',tipo);
    //     Api.post('menu.php',formData).then((response) => {
    //         if (cancel) return;

    //         if (response.data.erro==='N') {             
    //             //MontarMenu(response.data.msg);
    //             setMenus(response.data.msg);
    //         } else {
    //             console.log('menu erros', response.data.erro);
    //             //Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
    //         }
    //     }).catch(({response}) => {
    //         console.log('catch: '+JSON.stringify(response));
    //     });  

    //     return () => {
    //         cancel = true;
    //     };
    // }

    // useEffect(() => {        
    //     getMenu();
    //     //eslint-disable-next-line
    // },[]);
    
    useEffect(() => {        
        setMenus(data);
    },[data]);

    return <>
        {                     
            <div className="offcanvas offcanvas-start bg-dark m-0 p-0" tabIndex="-1" id="offcanvasRuntime" aria-labelledby="offcanvasRuntimeLabel">
                <div className="offcanvas-header bg-color2 navbar-height">
                    <Logo height="48" id="offcanvasRuntimeLabel"/>
                    <button type="button" className="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-0">
                    <div className="mx-2 my-2">
                        <Perfil/>
                    </div>
                    <div className="w-100">
                        <div className="accordion accordion-flush accordion-pett" id="menumobile">
                            <IfComponent condicional={ menus.length > 0 }>
                                {                     
                                    menus.map(menu => (
                                        <React.Fragment key={ menu.MEN_CODIGO }>
                                            <IfComponent condicional={ menu.MEN_DROP==="S" } >
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={'H'+menu.MEN_CODIGO}>
                                                        <button className="accordion-button accordion-button-pett ps-3 py-3 pe-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#MEN'+menu.MEN_CODIGO} aria-expanded="false" aria-controls={'MEN'+menu.MEN_CODIGO}>{menu.MEN_DESCRICAO}</button>
                                                    </h2>
                                                    <div id={'MEN'+menu.MEN_CODIGO} className="accordion-collapse collapse" aria-labelledby={'H'+menu.MEN_CODIGO} data-bs-parent="#menumobile">
                                                        <div className="accordion-body p-0 ps-3">
                                                            <div className="accordion accordion-flush" id={'ac'+menu.MEN_CODIGO}>
                                                                <MenuFilhos data={menu.MEN_FILHOS} parent={'ac'+menu.MEN_CODIGO}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </IfComponent>
                                            <IfComponent condicional={ menu.MEN_DROP==="N" } >
                                                <div className="accordion-item accordion-item-pett">
                                                    <div className="accordion-body ps-3 py-3 pe-2"><a href={menu.MEN_LINK} className="text-uppercase d-block" target={menu.MEN_TARGET}>{menu.MEN_DESCRICAO}</a></div>
                                                </div>
                                            </IfComponent>
                                        </React.Fragment>
                                    ))
                                }
                            </IfComponent>
                        </div>
                    </div>
                </div>
            </div>
        }        
    </>
}

function MenuFilhos(props) {
    const { data, parent } = props;
    // const location = window.location;

    return <>
        <IfComponent condicional={ data.length > 0 }>
            {
                data.map(menu => (
                    <React.Fragment key={ menu.MEN_CODIGO }>
                        <IfComponent condicional={ menu.MEN_DROP==="S" }>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={'H'+menu.MEN_CODIGO}>
                                    <button className="accordion-button accordion-button-pett ps-3 py-3 pe-2 collapsed text-uppercase" type="button" data-bs-toggle="collapse" data-bs-target={'#MEN'+menu.MEN_CODIGO} aria-expanded="false" aria-controls={'MEN'+menu.MEN_CODIGO}>{menu.MEN_DESCRICAO}</button>
                                </h2>
                                <div id={'MEN'+menu.MEN_CODIGO} className="accordion-collapse collapse" aria-labelledby={'H'+menu.MEN_CODIGO} data-bs-parent={'#'+parent}>
                                    <div className="accordion-body p-0 ps-3">
                                        <div className="accordion accordion-flush" id={'ac'+menu.MEN_CODIGO}>
                                            <MenuFilhos data={menu.MEN_FILHOS} parent={'ac'+menu.MEN_CODIGO}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IfComponent>
                        <IfComponent condicional={ menu.MEN_DROP==="N" }>
                            <div className="accordion-item accordion-item-pett">
                                <div className="accordion-body ps-3 py-3 pe-2"><a href={menu.MEN_LINK} className="text-uppercase d-block" target={menu.MEN_TARGET}>{menu.MEN_DESCRICAO}</a></div>
                            </div>
                        </IfComponent>
                    </React.Fragment>
                ))
            }
        </IfComponent>
    </>
}

export default MenuMobile;