import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import IfComponent from '../../componentes/IfComponent';
import Rodape from '../../componentes/Rodape';

function Zaps() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ operacao, setOperacao ] = useState('listar');
    const [ zap, setZap ] = useState({});

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            // formData.set('campo', pesquisa.campo);
            // formData.set('pesquisa', pesquisa.texto);
            Api.post('admin/zaps.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar) {
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listar(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/zaps.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listar(0,false,true);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    function editar(item) {
        console.log(item);
        setZap(item);
        setOperacao('atualizar');
        // var formData = new FormData();
        // formData.set('op', 'ver');
        // formData.set('codigo', codigo);
        // Api.post('admin/musicas.php',formData).then((response) => {
        //     if (response.data.erro==='N') {
        //         setMusica(response.data.msg);
        //     } else {
        //         setOperacao('listar');
        //         response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        //     }
        // }).catch(({response}) => {
        //     console.log('CATCH Editar Categoria');
        // });        
    }

    function cancelar() {
        setOperacao('listar');
    }

    async function salvar() {
        const formData = new FormData();
        formData.set('op',operacao);
        formData.set('ZAP_CODIGO',zap.ZAP_CODIGO);
        formData.set('ZAP_NOME',zap.ZAP_NOME);
        formData.set('ZAP_FONE',zap.ZAP_FONE);
        
        const response = await Api.post('admin/zaps.php',formData);
        if (response.data.erro==='N') {
            let novaListagem = [];
                //edição.
                Swal.fire({title: 'Yessss!', text: 'Registro atualizado com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem = listagem.filter(item => (String(item.ZAP_CODIGO) !== String(zap.ZAP_CODIGO)));
                novaListagem.unshift(response.data.msg); //adiciona no inicio da listagem.
            //}
            
            //ordena a listagem.
            const novaListagemSort = novaListagem.sort(function(c1,c2) {
                if (c1.ZAP_NOME > c2.ZAP_NOME) return  1;
                if (c1.ZAP_NOME < c2.ZAP_NOME) return -1;
                return 0;
            });
            
            setListagem(novaListagemSort);
            setOperacao('listar');
            setZap({});
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="zap") {
            setZap({...zap, [name]: valor});
        }
        // if (objeto==="pesquisa") {
        //     setPesquisa({...pesquisa, [name]: valor});
        // }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left={false} right={false}>
            <Cabecalho titulo='Lista para transmissão' texto=''/>
            
            <IfComponent condicional={operacao==="atualizar"}>
                <div className="row mb-3 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="ZAP_NOME" value={zap.ZAP_NOME || ''} onChange={(e) => setCampo(e,'zap',false)} />
                            <label htmlFor="ZAP_NOME">NOME</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="ZAP_FONE" value={zap.ZAP_FONE || ''} onChange={(e) => setCampo(e,'zap',false)} mask="99 99999-9999" />
                            <label htmlFor="ZAP_FONE">WHATSAPP</label>
                        </span>
                    </div>
                    <div className="box shadow-sm mt-3 mb-4">
                        <button className="btn btn-success me-2" onClick={() => salvar()}><i className="fa fas fa-save"></i> Salvar</button>
                        <button className="btn btn-dark" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                    </div>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <IfComponent condicional={ listagem.length > 0}>
                    <div className="container mt-4 p-0"></div>
                    <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>Fone</th>
                                <th style={{ textAlign: 'right'}}>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {                     
                                listagem.map((zap,idx) => (
                                    <tr key={zap.ZAP_CODIGO}>
                                        <td>{idx + 1}</td>
                                        <td>{zap.ZAP_CODIGO}</td>
                                        <td>{zap.ZAP_NOME}</td>
                                        <td>{zap.ZAP_FONE}</td>
                                        <td align="right">
                                            <Link to="" onClick={() => editar({ 'ZAP_CODIGO': zap.ZAP_CODIGO, 'ZAP_NOME': zap.ZAP_NOME, 'ZAP_FONE': zap.ZAP_FONE })} title="Editar"><i className="fa fa-fw fa-pencil"></i></Link>
                                            <Link to="" onClick={() => excluir({ 'codigo': zap.ZAP_CODIGO, 'nome': zap.ZAP_NOME, 'fone': zap.ZAP_FONE })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                        </td>
                                    </tr>
                                ))
                            }   
                        </tbody>
                    </table>
                </IfComponent>
            </IfComponent>

            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-whatsapp"></i></span><br/>Nenhum registro encontrado!</p>
                </div>                    
            </IfComponent>
            <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default Zaps;