import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
//import { InputMask } from 'primereact/inputmask';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';

function CriarConta() {
    const navigate = useNavigate();
    const { logar } = useContext(AuthContext);
    const [ enviando, setEnviando ] = useState(false);  
    const [ nome, setNome ] = useState('');  
    const [ email, setEmail ] = useState('');  
    const [ senha1, setSenha1 ] = useState('');
    const [ senha2, setSenha2 ] = useState('');

    function enviarCadastro() {
        setEnviando(true);
        const formData = new FormData();
        formData.set('op','criarconta');
        formData.set('USU_NOME',nome || '');
        formData.set('USU_EMAIL',email || '');
        formData.set('USU_PASSWORD1',senha1 || '');
        formData.set('USU_PASSWORD2',senha2 || '');
        Api.post('usuario/criarconta.php',formData).then((response) => {
            if (response.data.erro==='N') {
                logar(email,senha1);
                Swal.fire({title: 'Yessss!', text: 'Cadastro realizado com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                setEnviando(false);
                navigate('/confirmar');
                // return <Navigate to='/confirmarcadastro' />
            } 
            if (response.data.erro==='S') {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                setEnviando(false);
            }
        }).catch(({response}) => {
            console.log('catch: '+JSON.stringify(response));
            setEnviando(false);
        });
    }

    return <>
        <div className="container-fluid">
            <div className="d-flex align-items-center text-center form-login-container">
                <div className="form-login w-100 pett-input">
                    <img src='/imagens/logo_crazy_maria_branco.svg' style={{maxWidth: '170px'}} alt='Crazy Maria' />
                    {/* { mensagem!=='' ? <div className="alert alert-danger mb-2" role="alert">{mensagem}</div> : null } */}
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1 mt-3">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_NOME" value={nome || ''} onChange={(e) => setNome(e.target.value)}/>
                            <label htmlFor="USU_NOME">NOME COMPLETO</label>
                        </span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_EMAIL" value={email || ''} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="USU_EMAIL">EMAIL</label>
                        </span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Password className="w-100" name="USU_SENHA1" value={senha1} onChange={(e) => setSenha1(e.target.value)} toggleMask />
                            {/* <Password className="w-100" name="USU_SENHA1" value={cadastro.USU_SENHA1} onChange={(e) => setCampo(e,'cadastro',false)} toggleMask /> */}
                            <label htmlFor="USU_SENHA1">SENHA</label>
                        </span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                        <span className="p-float-label pett-label">
                            <Password className="w-100" name="USU_SENHA2" value={senha2} onChange={(e) => setSenha2(e.target.value)} toggleMask feedback={false}/>
                            <label htmlFor="USU_SENHA2">CONFIRME A SENHA</label>
                        </span>
                    </div>

                    {
                        enviando===false
                            ? <button className="btn btn-lg btn-success me-2 w-100 mb-3" onClick={() => enviarCadastro()}><i className="fa fas fa-paper-plane"></i> Crie minha conta</button>
                            : <button className="btn btn-lg btn-success me-2 w-100 mb-3" disabled><i className='fa fa-fw fa-spin fa-spinner'></i> Criando sua conta...</button>
                    }
                    
                    <Link className="btn btn-outline-success w-100 mb-4" to="/login">Já tenho conta</Link>
                    <Link className="link-secondary w-100 mb-4" to="/">Voltar para o site</Link>
                </div>
            </div>
        </div>
    </>
}

export default CriarConta;