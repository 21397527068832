import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';
import ColunaCentral from '../componentes/ColunaCentral';
import IfComponent from '../componentes/IfComponent';
import Rodape from '../componentes/Rodape';

function Repertorio() {
    const [ listagem, setListagem ] = useState([]);

    function listar() {
        var formData = new FormData();
        formData.set('op', 'listar');       
        Api.post('repertorio.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setListagem(response.data.msg);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });            
    }

    useEffect(() => {
        listar();    
        // eslint-disable-next-line
    },[]);

    return <>
        <BarraSuperior/>
        <ColunaCentral left={false} right={false}>
            <IfComponent condicional={listagem.length > 0}>
                <div className="container m-0 p-0 g-0 mb-3">
                    {                     
                        listagem.map((item,idx) => (
                            <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={item.MUS_CODIGO}>
                                <div className="row g-0">
                                    <div className="col-12 pt-1 px-2 lh-2 text-truncate">
                                        <a href={item.MUS_LINK} className="link-preto stretched-link fw-bold">{item.MUS_NOME}</a>
                                    </div>
                                    <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small d-flex justify-content-between">
                                        <span>{idx+1+' - '}{item.MUS_TIPO==="N"?"NACIONAL":"INTERNACIONAL"}</span><span>Duração: {item.MUS_TEMPO}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }   
                </div>
            </IfComponent>
        </ColunaCentral>
        <Rodape />
    </>
}

export default Repertorio;