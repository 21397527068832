import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import Rodape from '../../componentes/Rodape';
import IfComponent from '../../componentes/IfComponent';

function PaginasHits() {
    const { id } = useParams();
    const { logout } = useContext(AuthContext);
    const [ blocos, setBlocos ] = useState([]);
    const [ listagem, setListagem ] = useState([]);
    const [ pesquisa, setPesquisa ] = useState({
        datainicial: '',
        datafinal: '',
        agrupar: '',
        bloco: id,
    });  
    
    const SelectItemsAGRUPAR = [
        {value: "BLH_ANO", label: "Por Ano" },
        {value: "BLH_MES", label: "Por Mês" },
        {value: "BLH_DIA", label: "Por Dia" }
    ];

    const getBlocos = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        formData.set('sopaginas','S');
        formData.set('comtodos','S');
        Api.post('admin/blocos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setBlocos(response.data.msg);
            }
        });
    },[]);

    useEffect(() => {
        getBlocos();
        console.log(id,blocos);
    },[getBlocos,id,blocos]);

    // useEffect(() => {
    //     // setPesquisa({
    //     //     datainicial: '',
    //     //     datafinal: '',
    //     //     agrupar: '',
    //     //     bloco: String(id),
    //     // });
    // },[]);
       
    async function pesquisar() {
        const formData = new FormData();
        formData.set('op','listar');
        formData.set('datainicial',pesquisa.datainicial.toLocaleDateString());
        formData.set('datafinal',pesquisa.datafinal.toLocaleDateString());
        formData.set('agrupar',pesquisa.agrupar);
        formData.set('bloco',pesquisa.bloco);
        const response = await Api.post('admin/blocoshits.php',formData);
        if (response.data.erro==='N') {
            if (response.data.contador > 0) {
                setListagem(response.data.msg);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: 'Sua pesquisa retornou zero resultados', icon: 'info', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } else {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    function reset() {
        window.location.reload();
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
            setListagem([]);
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral>
            <Cabecalho titulo='Hits das páginas' texto=''/>
            <div className="row mt-4 mb-2 gx-1 p-inputtext-sm pett-input">
                <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <Calendar className="w-100" name="datainicial" value={pesquisa.datainicial} onChange={(e) => setCampo(e,'pesquisa',false)} dateFormat="dd/mm/yy" />
                        <label htmlFor="datainicial">DATA INICIAL</label>
                    </span>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <Calendar className="w-100" name="datafinal" value={pesquisa.datafinal} onChange={(e) => setCampo(e,'pesquisa',false)} dateFormat="dd/mm/yy" />
                        <label htmlFor="datafinal">DATA FINAL</label>
                    </span>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" name="agrupar" value={pesquisa.agrupar || ''} options={SelectItemsAGRUPAR} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                        <label htmlFor="agrupar">AGRUPAMENTO</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label">
                        <Dropdown className="w-100" name="bloco" value={pesquisa.bloco || ''} options={blocos} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                        <label htmlFor="bloco">BLOCO</label>
                    </span>
                </div>
            </div>

            <div className="box shadow-sm mb-5">
                <button className="btn btn-primary me-2" type="button" name="pesquisar" onClick={ () => pesquisar() }><i className="fa fas fa-search"></i> Pesquisar</button>
                <button className="btn btn-outline-primary me-2" type="button" name="reset" onClick={ () => reset() }><i className="fa fas fa-refresh"></i> Limpar</button>
            </div>

            <IfComponent condicional={pesquisa.agrupar==='BLH_ANO' && listagem.length > 0}>
                <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark mb-5">
                    <thead>
                        <tr>
                            <td>Bloco</td>
                            <td>Nome</td>
                            <td>Ano</td>
                            <td>Hits</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listagem.map(item => (
                                <tr key={item.CONTADOR}>
                                    <td><Link to={'/paginas/'+item.BLH_BLOCO} target="_blank">{item.BLH_BLOCO}</Link></td>
                                    <td><Link to={'/paginas/'+item.BLO_SLUG} target="_blank">{item.BLO_NOME}</Link></td>
                                    <td>{item.BLH_ANO}</td>
                                    <td>{item.QTDE}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </IfComponent>

            <IfComponent condicional={pesquisa.agrupar==='BLH_MES' && listagem.length > 0}>
                <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark mb-5">
                    <thead>
                        <tr>
                            <td>Bloco</td>
                            <td>Nome</td>
                            <td>Ano/Mês</td>
                            <td>Hits</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listagem.map(item => (
                                <tr key={item.CONTADOR}>
                                    <td><Link to={'/paginas/'+item.BLH_BLOCO} target="_blank">{item.BLH_BLOCO}</Link></td>
                                    <td><Link to={'/paginas/'+item.BLO_SLUG} target="_blank">{item.BLO_NOME}</Link></td>
                                    <td>{item.BLH_ANO}</td>
                                    <td>{item.QTDE}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </IfComponent>

            <IfComponent condicional={pesquisa.agrupar==='BLH_DIA' && listagem.length > 0}>
                <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark mb-5">
                    <thead>
                        <tr>
                            <td>Bloco</td>
                            <td>Nome</td>
                            <td>Ano/Mês/Dia</td>
                            <td>Hits</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listagem.map(item => (
                                <tr key={item.CONTADOR}>
                                    <td><Link to={'/paginas/'+item.BLH_BLOCO} target="_blank">{item.BLH_BLOCO}</Link></td>
                                    <td><Link to={'/paginas/'+item.BLO_SLUG} target="_blank">{item.BLO_NOME}</Link></td>
                                    <td>{item.BLH_ANO}</td>
                                    <td>{item.QTDE}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </IfComponent>


        </ColunaCentral>
        <Rodape/>
    </>
}

export default PaginasHits; 