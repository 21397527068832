import React from 'react';
// import { Link } from 'react-router-dom';

function NoticiaArquivos(props) {
    const { anoAtual, anosRestantes } = props;
    return <>
        <div className="box">
            <blockquote className="titulo mb-3">Arquivos</blockquote>
            <ul className="list-group list-group-flush">
                { anoAtual.map((item,idx) => (
                    <li className="list-group-item bg-transparent" key={idx}>
                        <a data-bs-toggle="collapse" href={'#Ano'+item.ANO} role="button" aria-expanded="false" aria-controls={'Ano'+item.ANO}>{item.ANO}</a>
                        <ul className="list-group list-group-flush collapse" id={'Ano'+item.ANO}>
                            { item.DADOS.map((dado,idx) => (
                                <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent" key={idx}>
                                    <a href={'/news/ano/'+item.ANO+'/'+dado.NTA_MES}>{dado.EXTENSO}</a>
                                    <span className="badge bg-success rounded-pill">{dado.NTA_QUANTIDADE}</span>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>

            <ul className="list-group list-group-flush">
                <li className="list-group-item bg-transparent">
                    <a data-bs-toggle="collapse" href="#Anteriores" role="button" aria-expanded="false" aria-controls="Anteriores">Anos Anteriores</a>
                    <ul className="list-group list-group-flush collapse" id="Anteriores">
                        {
                            anosRestantes.map((item,idx) => (                                           
                                <li className="list-group-item bg-transparent" key={idx}>
                                    <a data-bs-toggle="collapse" href={'#Ano'+item.ANO} role="button" aria-expanded="false" aria-controls={'Ano'+item.ANO}>{item.ANO}</a>
                                    <ul className="list-group list-group-flush collapse" id={'Ano'+item.ANO}>
                                        {
                                            item.DADOS.map((dado,idx) => (
                                                <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent" key={idx}>
                                                    <a href={'/news/ano/'+item.ANO+'/'+dado.NTA_MES}>{dado.EXTENSO}</a>
                                                    <span className="badge bg-success rounded-pill">{dado.NTA_QUANTIDADE}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            ))    
                        }
                    </ul>
                </li>
            </ul>
        </div>
    </>
}

export default NoticiaArquivos;