import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import Rodape from '../../componentes/Rodape';
import IfComponent from '../../componentes/IfComponent';

function Cidades() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ pesquisa, setPesquisa ] = useState({
        campo: 'CID_NOME',
        texto: ''
    });
    const [ cidade, setCidade ] = useState({CID_NOME: ''});
    const [ operacao, setOperacao ] = useState('listar');
    const SelectItemsTIPO = [
        {value: "CID_CODIGO", label: "Código" },
        {value: "CID_NOME", label: "Nome" }
    ];

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
                formData.set('op', 'listar');
                formData.set('pagina', novaPagina);
                formData.set('campo', pesquisa.campo);
                formData.set('pesquisa', pesquisa.texto);
            Api.post('admin/cidades.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar) {
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listar(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function pesquisar() {
        setPagina(0);
        setFinal(false);
        listar(0,false,true);
    };

    function reset() {
        window.location.reload();
        // const pesquisa = { campo: 'CON_NOME', texto: '' };
        // //setPesquisa({...pesquisa, texto: ''});
        // setPesquisa(pesquisa);
        // setPagina(0);
        // setFinal(false);
        // listar(0,false,true);
    };

    function cancelar() {
        setOperacao('listar');
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="cidade") {
            setCidade({...cidade, [name]: valor});
        }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
        }
    }

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/cidades.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listar(0,false,true);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    function novo() {
        setCidade(cidade);
        setOperacao('inserir');
    }

    async function salvar() {
        const formData = new FormData();
        formData.set('op',operacao);
        formData.set('CID_CODIGO',cidade.CID_CODIGO);
        formData.set('CID_ESTADO',cidade.CID_ESTADO);
        formData.set('CID_ESTADOCODIGO',cidade.CID_ESTADOCODIGO);
        formData.set('CID_NOME',cidade.CID_NOME);
        const response = await Api.post('admin/cidades.php',formData);
        if (response.data.erro==='N') {
            let novaListagem = [];
            if (cidade.CID_CODIGO===0) { 
                //inserção.
                Swal.fire({title: 'Yessss!', text: 'Cidade cadastrada com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem.push(...listagem);
                novaListagem.push(response.data.msg);
            } else {
                //edição.
                Swal.fire({title: 'Yessss!', text: 'Cidade atualizada com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem = listagem.filter(item => (String(item.CID_CODIGO) !== String(cidade.CID_CODIGO)));
                novaListagem.unshift(response.data.msg); //adiciona no inicio da listagem.
            }

            //ordena a listagem.
            const novaListagemSort = novaListagem.sort(function(c1,c2) {
                if (c1.CID_ESTADO > c2.CID_ESTADO) return  1;
                if (c1.CID_ESTADO < c2.CID_ESTADO) return -1;
                if (c1.CID_NOME > c2.CID_NOME) return  1;
                if (c1.CID_NOME < c2.CID_NOME) return -1;
                return 0;
            });

            setListagem(novaListagemSort);
            setOperacao('listar');
            setCidade({        
                CID_CODIGO: 0,
                CID_NOME: '',
            });
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    function editar(codigo) {
        setOperacao('atualizar');
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', codigo);
        Api.post('admin/cidades.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setCidade(response.data.msg);
            } else {
                setOperacao('listar');
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH Editar Cidade');
        });
    }

    return <>
        <BarraSuperior />
        <ColunaCentral left="false" right="false">
            <Cabecalho titulo="Cadastro de Cidades" texto="&nbsp;"/>

            <IfComponent condicional={operacao==="inserir" || operacao==="atualizar"}>
                <div className="row mt-3 mb-3 gx-1 p-inputtext-sm pett-input">
                <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            { 
                                operacao==="inserir"
                                    ? <InputMask className="w-100" maxLength="7" name="CID_CODIGO" value={cidade.CID_CODIGO || ''} onChange={(e) => setCampo(e,'cidade',true)} mask="9999999" />
                                    : <InputMask className="w-100" maxLength="7" name="CID_CODIGO" value={cidade.CID_CODIGO || ''} onChange={(e) => setCampo(e,'cidade',true)} mask="9999999" disabled/>
                            }                           
                            {/* <InputText className="w-100" maxLength="10" name="CID_CODIGO" value={cidade.CID_CODIGO || ''} onChange={(e) => setCampo(e,'cidade',false)} /> */}
                            <label htmlFor="texto">CÓDIGO IBGE</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" maxLength="2" name="CID_ESTADO" value={cidade.CID_ESTADO || ''} onChange={(e) => setCampo(e,'cidade',true)} mask="aa" />
                            <label htmlFor="texto">ESTADO</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" maxLength="2" name="CID_ESTADOCODIGO" value={cidade.CID_ESTADOCODIGO || ''} onChange={(e) => setCampo(e,'cidade',true)} mask="99" />
                            <label htmlFor="texto">CÓDIGO ESTADO</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="100" name="CID_NOME" value={cidade.CID_NOME || ''} onChange={(e) => setCampo(e,'cidade',true)} />
                            <label htmlFor="texto">NOME</label>
                        </span>
                    </div>
                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar()}><i className="fa fas fa-save"></i> Salvar</button>
                    <button className="btn btn-dark" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <div className="row mb-2 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="campo" value={pesquisa.campo || ''} options={SelectItemsTIPO} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                            <label htmlFor="campo">TIPO DE PESQUISA</label>
                        </span>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="texto" value={pesquisa.texto || ''} onChange={(e) => setCampo(e,'pesquisa',false)} />
                            <label htmlFor="texto">PESQUISA</label>
                        </span>
                    </div>
                </div>

                <div className="box shadow-sm d-flex justify-content-between">
                    <input type="hidden" value="pesquisar" name="op" />
                    <button className="btn btn-primary me-2" type="button" name="pesquisar" onClick={ () => pesquisar() }><i className="fa fas fa-search"></i> Pesquisar</button>
                    <button className="btn btn-outline-primary me-2" type="button" name="reset" onClick={ () => reset() }><i className="fa fas fa-refresh"></i> Limpar</button>
                    <button className="btn btn-success ms-auto" type="button" name="novo" onClick={ () => novo() }><i className="fa fas fa-plus"></i> Nova</button>
                </div>

                <IfComponent condicional={ listagem.length > 0}>
                    <div className="container mt-4 p-0"></div>
                    <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                        <thead>
                            <tr>
                                <th>Código IBGE</th>
                                <th>Código Estado</th>
                                <th>Estado</th>
                                <th>Nome</th>
                                <th style={{ textAlign: 'right'}}>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {                     
                                listagem.map(cidade => (
                                    <tr key={cidade.CID_CODIGO}>
                                        <td>{cidade.CID_CODIGO}</td>
                                        <td>{cidade.CID_ESTADOCODIGO}</td>
                                        <td>{cidade.CID_ESTADO}</td>
                                        <td>{cidade.CID_NOME}</td>
                                        <td align="right">
                                            <Link to="" onClick={() => editar(cidade.CID_CODIGO)} title="Editar"><i className="fa fa-fw fa-pencil"></i></Link>
                                            <Link to="" onClick={() => excluir({ 'codigo': cidade.CID_CODIGO, 'nome': cidade.CID_NOME })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                        </td>
                                    </tr>
                                ))
                            }   
                        </tbody>
                    </table>
                </IfComponent>
            </IfComponent>
    
            <div className="container limit-width bg-transparent pb-5" ref={scrollObserver} id="sentinela"></div>
            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-city"></i></span><br/>Nenhuma cidade encontrada!</p>
                </div>                    
            </IfComponent>

        </ColunaCentral>
        <Rodape />
    </>
}

export default Cidades;