import React from 'react';

function NoticiaTags(props) {
    const { tags } = props;
    return <>
        <div className="box">
            <blockquote className="titulo mb-3">Tags</blockquote>
            {
                tags.map((tag,idx) => (
                    <a className="badge bg-success me-1" href={'/news/tag/'+tag.TAG_TAG} key={idx}>{tag.TAG_TAG}</a>
                ))
            }
        </div>
    </>
}

export default NoticiaTags;