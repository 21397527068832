import React from 'react';

function NoticiaCategorias(props) {
    const { categorias } = props;
    return <>
        <div className="box">
            <blockquote className="titulo mb-3">Categorias</blockquote>
            <ol className="list-group list-group-flush list-group-numbered">
                {
                    categorias.map((categoria,idx) => (
                        <li className="list-group-item text-truncate bg-transparent" style={{ color: '#efefef'}} key={idx}>
                            <a href={'/news/cat/'+categoria.CAT_CODIGO}>{categoria.CAT_DESCRICAO}</a>
                        </li>
                    ))
                }   
            </ol>
        </div>    
    </>
}

export default NoticiaCategorias;