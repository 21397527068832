import React, { useCallback, useContext, useEffect, useRef, useState  } from "react";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
// import { InputNumber } from 'primereact/inputnumber';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import IfComponent from '../../componentes/IfComponent';
import Rodape from '../../componentes/Rodape';

function Banners() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ unidades, setUnidades ] = useState([]);
    const [ imageData, setImageData ] = useState();

    const [ banner, setBanner ] = useState({
        BAN_ARQUIVO: '',
        BAN_CODIGO: 0,
        BAN_DESCRICAO: '',
        BAN_GRUPO: '',
        BAN_LINK: '',
        BAN_ORDEM: '',
        BAN_UNIDADE: 1
    });

    const getUnidades = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('unidades.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setUnidades(response.data.msg);
            }
        });
    },[]);

    useEffect(() => {
        getUnidades();
    },[getUnidades]);

    function listarBanners(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var bodyFormData = new FormData();
                bodyFormData.set('op', 'listar');
                bodyFormData.set('pagina', novaPagina);
                //bodyFormData.set('campo', formPesquisar.campo);
                //bodyFormData.set('pesquisa', formPesquisar.texto);
                       
            Api.post('admin/banners.php',bodyFormData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarBanners(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);
    
    function editar(codigo) {
        var bodyFormData = new FormData();
        bodyFormData.set('op', 'ver');
        bodyFormData.set('codigo', codigo);
              
        Api.post('admin/banners.php',bodyFormData).then((response) => {
            if (response.data.erro==='N') {
                setBanner(response.data.msg);
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH Editar Banner');
        });        
    }

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529' 
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/banners.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listarBanners(0,false,true);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    const setCampo = (e) => {
        const { name, value } = e.target;
        let valor = value?value.toUpperCase():'';
        setBanner({...banner, [name]: valor});
    }

    const setCampoValor = (e) => {
        const { name, value } = e.target;
        setBanner({...banner, [name]: value});
    }  
   
    async function salvarBanner() {
        const formData = new FormData();
        banner.BAN_CODIGO===0?formData.set('op','inserir'):formData.set('op','atualizar');
        //formData.set('op','atualizar');
        formData.set('BAN_CODIGO',banner.BAN_CODIGO);
        formData.set('BAN_ARQUIVO',imageData);
        formData.set('BAN_DESCRICAO',banner.BAN_DESCRICAO);
        formData.set('BAN_GRUPO',banner.BAN_GRUPO);
        formData.set('BAN_LINK',banner.BAN_LINK);
        formData.set('BAN_ORDEM',banner.BAN_ORDEM);
        formData.set('BAN_UNIDADE',banner.BAN_UNIDADE);
        const response = await Api.post('admin/banners.php',formData);
        if (response.data.erro==='N') {
           
            let novaListagem = [];

            if (banner.BAN_CODIGO===0) { 
                //inserção.
                Swal.fire({title: 'Yessss!', text: 'Banner cadastrado com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem.push(...listagem);
                novaListagem.push(response.data.msg);
            } else {
                //edição.
                Swal.fire({title: 'Yessss!', text: 'Banner atualizado com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem = listagem.filter(item => (String(item.BAN_CODIGO) !== String(banner.BAN_CODIGO)));
                novaListagem.unshift(response.data.msg); //adiciona o banner atualizado no inicio da listagem.
            }
            
            //ordena a listagem.
            const novaListagemSort = novaListagem.sort(function(c1,c2) {
                if (c1.BAN_GRUPO > c2.BAN_GRUPO) return  1;
                if (c1.BAN_GRUPO < c2.BAN_GRUPO) return -1;
                if (c1.BAN_ORDEM > c2.BAN_ORDEM) return  1;
                if (c1.BAN_ORDEM < c2.BAN_ORDEM) return -1;
                return 0;
            });
            setListagem(novaListagemSort);

            setBanner({        
                BAN_ARQUIVO: '',
                BAN_CODIGO: 0,
                BAN_DESCRICAO: '',
                BAN_GRUPO: '',
                BAN_LINK: '',
                BAN_ORDEM: '',
                BAN_UNIDADE: 1
            });
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    function novo() {
        setBanner({        
            BAN_ARQUIVO: '',
            BAN_CODIGO: 0,
            BAN_DESCRICAO: '',
            BAN_GRUPO: '',
            BAN_LINK: '',
            BAN_ORDEM: '',
            BAN_UNIDADE: '1'
        });
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false">
            <Cabecalho titulo="Cadastro de Banners" texto="&nbsp;"/>
            <div className="row mt-3 mb-3 gx-1 p-inputtext-sm pett-input">
                <div className="col-lg-1 col-md-1 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputMask className="w-100" maxLength="2" name="BAN_GRUPO" value={banner.BAN_GRUPO || ''} onChange={(e) => setCampo(e)} mask="99" required/>
                        <label htmlFor="texto">GRUPO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="50" name="BAN_DESCRICAO" value={banner.BAN_DESCRICAO || ''} onChange={(e) => setCampoValor(e)} required/>
                        <label htmlFor="texto">DESCRIÇÃO</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="120" name="BAN_LINK" value={banner.BAN_LINK || ''} onChange={(e) => setCampoValor(e)} required/>
                        <label htmlFor="texto">LINK</label>
                    </span>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="5" name="BAN_ORDEM" value={banner.BAN_ORDEM || ''} onChange={(e) => setCampoValor(e)} required/>
                        <label htmlFor="texto">ORDEM</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" name="BAN_UNIDADE" value={String(banner.BAN_UNIDADE) || ''} options={unidades} onChange={(e) => setCampoValor(e)} placeholder="Selecione"/>
                        <label htmlFor="campo">UNIDADE</label>
                    </span>
                </div>
                {/* <div className="col-lg-12 col-md-12 col-sm-12" data-provides="fileinput">
                    <FileUpload chooseOptions={chooseOptions} mode="basic" name="BAN_ARQUIVO" accept="image/*" maxFileSize={1000000} onUpload={onBasicUpload} customUpload uploadHandler={(e) => myUploader(e)} />
                </div> */}

                <div className="col-lg-12 col-md-12 col-sm-12" data-provides="fileinput">
                    <div className="input-group">
                        <input className="form-control" type="file" id="BAN_ARQUIVO" name="BAN_ARQUIVO" onChange={(e) => setImageData(e.target.files[0])}/>
                    </div>
                </div>

                <div className="box shadow-sm mt-3 mb-4 d-flex justify-content-between">
                    <button className="btn btn-success" onClick={() => salvarBanner()}>Salvar</button>
                    <button className="btn btn-success" onClick={() => novo()}>Novo</button>
                </div>
            </div>

            <IfComponent condicional={ listagem.length > 0}>
                <div className="container mt-3 p-0"></div>
                <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Grupo</th>
                            <th>Descrição</th>
                            <th>Link</th>
                            <th>Unidade</th>
                            <th>Ordem</th>
                            <th>Imagem</th>
                            <th style={{ textAlign: 'right'}}>Opções</th>
                        </tr>
                    </thead>
                    <tbody>
                        {                     
                            listagem.map(banner => (
                                <tr key={ banner.BAN_CODIGO }>
                                    <td>{ banner.BAN_CODIGO }</td>
                                    <td>{ banner.BAN_GRUPO }</td>
                                    <td>{ banner.BAN_DESCRICAO }</td>
                                    <td>{ banner.BAN_LINK }</td>
                                    <td>{ banner.UNI_DESCRICAO }</td>
                                    <td>{ banner.BAN_ORDEM }</td>
                                    <td><img src={ banner.BAN_ARQUIVO } alt='' style={{maxHeight: '25px'}}/></td>
                                    <td align="right">
                                        <Link to="" onClick={() => editar(banner.BAN_CODIGO)} title="Editar"><i className="fa fa-fw fa-pencil"></i></Link>
                                        <Link to="" onClick={() => excluir({ 'codigo': banner.BAN_CODIGO, 'nome': banner.BAN_DESCRICAO })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                    </td>
                                </tr>
                            ))
                        }   
                    </tbody>
                </table>
            </IfComponent>           
            <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-note-sticky"></i></span><br/>Nenhum banner encontrado!</p>
                </div>                    
            </IfComponent>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default Banners;