import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import IfComponent from '../../componentes/IfComponent';
import NoticiaEditor from '../../componentes/NoticiaEditor';
import Rodape from '../../componentes/Rodape';

function Noticias() {
    const referenciaEditorResumo = useRef(null);
    const referenciaEditorTexto = useRef(null);
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ pesquisa, setPesquisa ] = useState({
        campo: 'NOT_DATA',
        datainicial: '',
        datafinal: '',
        texto: ''
    });
    const [ unidades, setUnidades ] = useState([]);
    const [ categorias, setCategorias ] = useState([]);
    const [ noticia, setNoticia ] = useState({
        NOT_ANO: '',
        NOT_CATEGORIA: '',
        NOT_CODIGO: 0,
        NOT_CSS: '',
        NOT_DATA: '',
        NOT_DATAUNIX: '',
        NOT_DESTAQUE: 'N',
        NOT_DTATUALIZACAO: '',
        NOT_HITS: '',
        NOT_IMAGEMDESTAQUE: '',
        NOT_LEIAMAIS: '',
        NOT_PUBLICADO: '',
        NOT_PUBLICADOPOR: '',
        NOT_RESUMO: '',
        NOT_SLUG: '',
        NOT_TAGS: '',
        NOT_TEXTO: '',
        NOT_TITULO: '',
        NOT_UNIDADE: 1,
        NOT_USUARIO: '',
        NOT_VALIDADEINICIO: '',
        NOT_VALIDADEFIM: '',
        ARQUIVOS: []
    });
    const [ operacao, setOperacao ] = useState('listar');

    const SelectItemsTIPO = [
        {value: "NOT_TITULO", label: "Título" },
        {value: "NOT_DATA", label: "Período Cadastro" },
        {value: "NOT_SLUG", label: "Slug" },
        {value: "NOT_CODIGO", label: "Código" },
        {value: "CAT_DESCRICAO", label: "Categoria" },
        {value: "UNI_DESCRICAO", label: "Unidade" }
    ];

    const SelectItemsCSS = [
        {value: "DEFAULT", label: "Default" },
        {value: "PRIMARY", label: "Primary" },
        {value: "SUCCESS", label: "Success" },
        {value: "WARNING", label: "Warning" },
        {value: "INFO", label: "Info" },
        {value: "DANGER", label: "Danger" }
    ];    

    const SelectItemsDESTAQUE = [
        {value: "S", label: "SIM" },
        {value: "N", label: "NÃO" }
    ];    

    const SelectItemsLEIAMAIS = [
        {value: "S", label: "SIM" },
        {value: "N", label: "NÃO" }
    ];    

    const SelectItemsPUBLICADO = [
        {value: "S", label: "SIM" },
        {value: "N", label: "NÃO" }
    ];    

    const getUnidades = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('unidades.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setUnidades(response.data.msg);
            }
        });
    },[]);

    const getCategorias = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('admin/categorias.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setCategorias(response.data.msg);
            }
        });
    },[]);

    useEffect(() => {
        getUnidades();
        getCategorias();
    },[getUnidades,getCategorias]);

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
                formData.set('op', 'listar');
                formData.set('pagina', novaPagina);
                formData.set('campo', pesquisa.campo);
                formData.set('pesquisa', pesquisa.texto);
                formData.set('datainicial', pesquisa.datainicial);
                formData.set('datafinal', pesquisa.datafinal);
                       
            Api.post('admin/noticias.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listar(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function pesquisar() {
        setPagina(0);
        setFinal(false);
        listar(0,false,true);
    };

    function reset() {
        window.location.reload();
    };

    function cancelar() {
        setOperacao('listar');
    }

    function editar(codigo) {
        setOperacao('atualizar');
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', codigo);
              
        Api.post('admin/noticias.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setNoticia(response.data.msg);
            } else {
                setOperacao('listar');
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH: Editar Noticia');
        });        
    }

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/noticias.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listar(0,false,true);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('CATCH: '+JSON.stringify(response));
                });
            }
        });
    }

    function novo() {
        const formData = new FormData();
        formData.set('op','novo');
        Api.post('admin/noticias.php',formData).then((response) => {
            if (response.data.erro==='N') {                 
                setNoticia(response.data.msg);
                setOperacao('atualizar');
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        });
    }

    async function salvar(manterNaPagina) {
        setNoticia({...noticia, NOT_TEXTO: referenciaEditorTexto.current.getContent()}); //atualiza o conteudo do state bloco com o conteudo do editor.
        setNoticia({...noticia, NOT_RESUMO: referenciaEditorResumo.current.getContent()}); //atualiza o conteudo do state bloco com o conteudo do editor.

        const formData = new FormData();
        formData.set('op','atualizar');
        formData.set('NOT_CATEGORIA',noticia.NOT_CATEGORIA);
        formData.set('NOT_CODIGO',noticia.NOT_CODIGO);
        formData.set('NOT_CSS',noticia.NOT_CSS);
        formData.set('NOT_DATA',noticia.NOT_DATA);
        formData.set('NOT_DESTAQUE',noticia.NOT_DESTAQUE);
        formData.set('NOT_LEIAMAIS',noticia.NOT_LEIAMAIS);
        formData.set('NOT_PUBLICADO',noticia.NOT_PUBLICADO);
        formData.set('NOT_TITULO',noticia.NOT_TITULO);
        formData.set('NOT_PAGINA',noticia.NOT_PAGINA);
        //formData.set('NOT_RESUMO',noticia.NOT_RESUMO);
        formData.set('NOT_RESUMO',referenciaEditorResumo.current.getContent());
        formData.set('NOT_SLUG',noticia.NOT_SLUG);
        formData.set('NOT_TAGS',noticia.NOT_TAGS);
        //formData.set('NOT_TEXTO',noticia.NOT_TEXTO);
        formData.set('NOT_TEXTO',referenciaEditorTexto.current.getContent());
        formData.set('NOT_UNIDADE',noticia.NOT_UNIDADE);
        formData.set('NOT_VALIDADEINICIO',noticia.NOT_VALIDADEINICIO);
        formData.set('NOT_VALIDADEFIM',noticia.NOT_VALIDADEFIM);
        const response = await Api.post('admin/noticias.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
        manterNaPagina?setOperacao('atualizar'):setOperacao('listar');
    }
    
    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="noticia") {
            setNoticia({...noticia, [name]: valor});
        }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
        }
    }

    async function setDestaque(e,codigo) {
        const formData = new FormData();
        formData.set('op','destaque');
        formData.set('codigo',codigo);
        formData.set('destaque',e.value===true?'S':'N');
        const response = await Api.post('admin/noticias.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            const listagemAtualizada = listagem.map(noticia => {
                //retorna o registro com o campo atualizado.
                if (noticia.NOT_CODIGO === codigo) {
                    return {...noticia, NOT_DESTAQUE: e.value===true?'S':'N'}
                }    
                //returna o registro original.
                return noticia;
            });
            setListagem(listagemAtualizada);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function setLeiamais(e,codigo) {
        const formData = new FormData();
        formData.set('op','leiamais');
        formData.set('codigo',codigo);
        formData.set('leiamais',e.value===true?'S':'N');
        const response = await Api.post('admin/noticias.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            const listagemAtualizada = listagem.map(noticia => {
                //retorna o registro com o campo atualizado.
                if (noticia.NOT_CODIGO === codigo) {
                    return {...noticia, NOT_LEIAMAIS: e.value===true?'S':'N'}
                }    
                //returna o registro original.
                return noticia;
            });
            setListagem(listagemAtualizada);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function setPublicado(e,codigo) {
        const formData = new FormData();
        formData.set('op','publicado');
        formData.set('codigo',codigo);
        formData.set('publicado',e.value===true?'S':'N');
        const response = await Api.post('admin/noticias.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            const listagemAtualizada = listagem.map(noticia => {
                //retorna o registro com o campo atualizado.
                if (noticia.NOT_CODIGO === codigo) {
                    return {...noticia, NOT_PUBLICADO: e.value===true?'S':'N'}
                }    
                //returna o registro original.
                return noticia;
            });
            setListagem(listagemAtualizada);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }    
  
    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false">
            <Cabecalho titulo="Cadastro de Notícias" texto="&nbsp;"/>

            <IfComponent condicional={operacao==="inserir" || operacao==="atualizar"}>
                <div className="row mt-3 mb-3 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" maxLength="10" name="NOT_DATA" mask="99/99/9999" value={noticia.NOT_DATA || ''} onChange={(e) => setCampo(e,'noticia',false)} />
                            <label htmlFor="NOT_DATA">DATA</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="NOT_TITULO" value={noticia.NOT_TITULO || ''} onChange={(e) => setCampo(e,'noticia',false)} />
                            <label htmlFor="NOT_TITULO">TÍTULO</label>
                        </span>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="NOT_SLUG" value={noticia.NOT_SLUG || ''} onChange={(e) => setCampo(e,'noticia',false)} />
                            <label htmlFor="NOT_SLUG">SLUG</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="NOT_CATEGORIA" value={String(noticia.NOT_CATEGORIA) || ''} options={categorias} onChange={(e) => setCampo(e,'noticia',false)} placeholder="Selecione"/>
                            <label htmlFor="NOT_CATEGORIA">CATEGORIA</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="NOT_LEIAMAIS" value={noticia.NOT_LEIAMAIS || ''} options={SelectItemsLEIAMAIS} onChange={(e) => setCampo(e,'noticia',false)} placeholder="Selecione"/>
                            <label htmlFor="NOT_LEIAMAIS">LEIA MAIS?</label>                                                               
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="NOT_PUBLICADO" value={noticia.NOT_PUBLICADO || ''} options={SelectItemsPUBLICADO} onChange={(e) => setCampo(e,'noticia',false)} placeholder="Selecione"/>
                            <label htmlFor="NOT_PUBLICADO">PUBLICADO?</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="NOT_DESTAQUE" value={noticia.NOT_DESTAQUE || ''} options={SelectItemsDESTAQUE} onChange={(e) => setCampo(e,'noticia',false)} placeholder="Selecione"/>
                            <label htmlFor="NOT_DESTAQUE">DESTAQUE?</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" maxLength="10" name="NOT_VALIDADEINICIO" mask="99/99/9999" value={noticia.NOT_VALIDADEINICIO || ''} onChange={(e) => setCampo(e,'noticia',false)} />
                            <label htmlFor="NOT_VALIDADEINICIO">VALIDADE INÍCIO</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" maxLength="10" name="NOT_VALIDADEFIM" mask="99/99/9999" value={noticia.NOT_VALIDADEFIM || ''} onChange={(e) => setCampo(e,'noticia',false)} />
                            <label htmlFor="NOT_VALIDADEFIM">VALIDADE FIM</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="NOT_TAGS" value={noticia.NOT_TAGS || ''} onChange={(e) => setCampo(e,'noticia',false)} />
                            {/* <Chips name='NOT_TAGS' value={noticia.NOT_TAGS2} onChange={(e) => setCampo(e,'noticia',false)} separator="," /> */}
                            <label htmlFor="NOT_TAGS">TAGS</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="NOT_IMAGEMDESTAQUE" value={noticia.NOT_IMAGEMDESTAQUE || ''} onChange={(e) => setCampo(e,'noticia',false)} />
                            <label htmlFor="NOT_IMAGEMDESTAQUE">IMAGEM DESTAQUE</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="NOT_CSS" value={noticia.NOT_CSS || ''} options={SelectItemsCSS} onChange={(e) => setCampo(e,'noticia',false)} placeholder="Selecione"/>
                            <label htmlFor="NOT_CSS">CSS?</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="NOT_UNIDADE" value={String(noticia.NOT_UNIDADE) || ''} options={unidades} onChange={(e) => setCampo(e,'noticia',false)} placeholder="Selecione"/>
                            <label htmlFor="NOT_UNIDADE">UNIDADE</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="NOT_CODIGO" value={noticia.NOT_CODIGO || ''} disabled/>
                            <label htmlFor="NOT_CODIGO">CÓDIGO</label>
                        </span>
                    </div>
                </div>
                {/* <BlocoEditor bloco={bloco} referenciaEditorTexto={referenciaEditorTexto}/> */}
                {/* <NoticiaEditor noticia={noticia} editorOnChange={editorOnChange} referenciaEditor={referenciaEditorTexto}/>  */}
                <NoticiaEditor noticia={noticia} referenciaEditor1={referenciaEditorTexto} referenciaEditor2={referenciaEditorResumo}/> 
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar(false)}>Salvar e fechar</button>
                    <button className="btn btn-success me-2" onClick={() => salvar(true)}>Salvar</button>
                    <button className="btn btn-dark" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <div className="row mb-2 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="campo" value={pesquisa.campo || ''} options={SelectItemsTIPO} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                            <label htmlFor="campo">TIPO DE PESQUISA</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="datainicial" mask="99/99/9999" value={pesquisa.datainicial || ''} onChange={(e) => setCampo(e,'pesquisa',false)} ></InputMask>
                            <label htmlFor="texto">DATA INICIAL</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="datafinal" mask="99/99/9999" value={pesquisa.datafinal || ''} onChange={(e) => setCampo(e,'pesquisa',false)} ></InputMask>
                            <label htmlFor="texto">DATA FINAL</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="texto" value={pesquisa.texto || ''} onChange={(e) => setCampo(e,'pesquisa',false)} />
                            <label htmlFor="texto">TEXTO</label>
                        </span>
                    </div>
                </div>

                <div className="box shadow-sm d-flex justify-content-between">
                    <input type="hidden" value="pesquisar" name="op" />
                    <button className="btn btn-primary me-2" type="button" name="pesquisar" onClick={ () => pesquisar() }><i className="fa fas fa-search"></i> Pesquisar</button>
                    <button className="btn btn-outline-primary me-2" type="button" name="reset" onClick={ () => reset() }><i className="fa fas fa-refresh"></i> Limpar</button>
                    <button className="btn btn-success ms-auto" type="button" name="novo" onClick={ () => novo() }><i className="fa fas fa-plus"></i> Nova Notícia</button>
                </div>

                <IfComponent condicional={ listagem.length > 0}>
                    <div className="container mt-4 p-0"></div>
                    <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Data</th>
                                <th>Título</th>
                                <th>Categoria</th>
                                <th>Unidade</th>
                                <th style={{ textAlign: 'center'}}>Leia</th>
                                <th style={{ textAlign: 'center'}}>Publicado</th>
                                <th style={{ textAlign: 'center'}}>Destaque</th>
                                <th style={{ textAlign: 'right'}}>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {                     
                                listagem.map(noticia => (
                                    <tr key={noticia.NOT_CODIGO}>
                                        <td>{ noticia.NOT_CODIGO }</td>
                                        <td>{ noticia.NOT_DATA }</td>
                                        <td><a href={'/admin/noticiaspreview/'+noticia.NOT_SLUG} target="_blank" rel="noreferrer">{noticia.NOT_TITULO}</a></td>
                                        <td>{ noticia.CAT_DESCRICAO }</td>
                                        <td>{ noticia.UNI_DESCRICAO }</td>
                                        {/* <td align="center">{ noticia.NOT_LEIA==="S" ? <span className="badge rounded-pill bg-success">SIM</span> : <span className="badge rounded-pill bg-danger">NÃO</span> } </td> */}
                                        {/* <td align="center">{ noticia.NOT_PUBLICADO==="S" ? <span className="badge rounded-pill bg-success">SIM</span> : <span className="badge rounded-pill bg-danger">NÃO</span> } </td> */}
                                        <td align="center"><InputSwitch checked={noticia.NOT_LEIAMAIS==="S"} onChange={(e) => setLeiamais(e,noticia.NOT_CODIGO)} /></td>
                                        <td align="center"><InputSwitch checked={noticia.NOT_PUBLICADO==="S"} onChange={(e) => setPublicado(e,noticia.NOT_CODIGO)} /></td>
                                        <td align="center"><InputSwitch checked={noticia.NOT_DESTAQUE==="S"} onChange={(e) => setDestaque(e,noticia.NOT_CODIGO)} /></td>
                                        <td align="right">
                                            <Link to={'/admin/noticiashits/'+noticia.NOT_CODIGO} title="Hits"><i className="far fa-fw fa-hand-point-up"></i></Link>
                                            <Link to="" onClick={() => editar(noticia.NOT_CODIGO)} title="Editar"><i className="fa fa-fw fa-pencil"></i></Link>
                                            <Link to="" onClick={() => excluir({ 'codigo': noticia.NOT_CODIGO, 'nome': noticia.NOT_TITULO })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                        </td>
                                    </tr>
                                ))
                            }   
                        </tbody>
                    </table>
                </IfComponent>           
            </IfComponent>           
            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-newspaper"></i></span><br/>Nenhuma notícia encontrada!</p>
                </div>                    
            </IfComponent>
        </ColunaCentral>
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <Rodape/>
    </>
}

export default Noticias;