import React from 'react';

import IfComponent from './IfComponent';

function NoticiaCorpo(props) {
    const { noticia, visualizar, tags } = props;

    return <>
        <IfComponent condicional={noticia}>
            <p className="news"><a href={'/news/'+noticia.NOT_SLUG}>{noticia.NOT_TITULO}</a></p>
            <small>
                <p className="" style={{marginTop: '-6px'}}>
                    <strong>{noticia.NOT_DATA} </strong>
                    - Publicado por {noticia.USU_APELIDO}&nbsp;
                    - Categoria: <a href={'/news/cat/'+noticia.NOT_CATEGORIA}>{noticia.CAT_DESCRICAO} </a> 
                    - Tags: {
                        <IfComponent condicional={tags.length>0}>
                            {
                                tags.map((tag,idx) => (
                                    <span className="badge bg-danger text-uppercase me-1" key={idx}>{tag}</span>
                                ))
                            }
                        </IfComponent>
                    }
                </p>
            </small>

            <IfComponent condicional={visualizar===true}>
                <section className="" dangerouslySetInnerHTML={{ __html: noticia.NOT_TEXTO }} />
            </IfComponent>

            <IfComponent condicional={visualizar===false}>
                <section className="" dangerouslySetInnerHTML={{ __html: noticia.NOT_RESUMO }} />
                {
                    noticia.NOT_LEIAMAIS==="S"?<a href={'/news/'+noticia.NOT_SLUG}>Continue lendo...</a>:''
                }
            </IfComponent>
            <hr style={{ border: '2px solid #000'}}/>
        </IfComponent>
    </>
}

export default NoticiaCorpo;