import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import ListagemArquivos  from './ListagemArquivos';

function BlocoEditor(props) {
    const [ arquivos, setArquivos ] = useState(props.bloco.ARQUIVOS || []);

    //código utilizado pra obter a instancia do editor.
    // const editorRef = useRef(null);
    // const log = () => {
    //     if (editorRef.current) {
    //         console.log(editorRef.current.getContent());
    //     }
    // };

    useEffect(()=>{
        setArquivos(props.bloco.ARQUIVOS || []);
    },[props.bloco.ARQUIVOS]);

    const imageUploadHandler = (blobInfo, progress) => new Promise((resolve, reject) => {       
        const tokenStorage = localStorage.getItem('token');
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', process.env.REACT_APP_URL_API + '/admin/blocoAcceptor.php');
        xhr.setRequestHeader('Authorization', `Bearer ${tokenStorage}`);

        xhr.upload.onprogress = (e) => {
            progress(e.loaded / e.total * 100);
        };
      
        xhr.onload = () => {
            if (xhr.status === 403) {
                reject({ message: 'HTTP Error: ' + xhr.status + ' - ' + xhr.statusText, remove: true });
                return;
            }
      
            if (xhr.status !== 200) {
                reject('HTTP Error: ' + xhr.status);
                return;
            }
      
            const json = JSON.parse(xhr.responseText);

            if (json.erro==='S') {
                reject(json.msg);
                return;
            }
      
            resolve(json.location);
            setArquivos(json.arquivos);
        };
      
        xhr.onerror = () => {
            reject('Falha no envio do arquivo. Erro: ' + xhr.status);
        };
      
        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        formData.append('op','inserir');
        formData.append('BLO_CODIGO', props.bloco.BLO_CODIGO);
      
        xhr.send(formData);
    });

    return (
        <div>
            <Editor
                apiKey='ly6hr1bka7s3t7ud9o2omflzzdmrx4ydmpkvqns8rj5c4i6b'
                onInit={(evt, editor) => props.referenciaEditor.current = editor}
                //onInit={(evt, editor) => editorRef.current = editor} //passamos a referencia por props para ter acesso em paginas.jsx
                initialValue={props.bloco.BLO_CONTEUDO}
                init={{
                    paste_as_text: true,
                    relative_urls: false,
                    //remove_script_host: false,
                    //convert_urls: true, //https://www.tiny.cloud/docs/configure/url-handling/
                    document_base_url: 'https://www.runtimesistemas.com.br',
                    images_upload_url: process.env.REACT_APP_URL_API + '/admin/blocoAcceptor.php',
                    images_upload_handler: imageUploadHandler,
                    images_upload_credentials: true,
                    //image_prepend_url: 'https://www.runtimesistemas.com.br/',
                    //typeahead_urls: false,
                    image_advtab: true,
                    image_dimensions: true,
                    image_title: true,
                    image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'img-fluid', value: 'img-fluid'},
                        {title: 'img-thumbnail', value: 'img-thumbnail'}
                    ],
                    style_formats: [
                        {title: 'Image Left', selector: 'img', styles: {
                          'float' : 'left',
                          'margin': '0 10px 0 10px'
                        }},
                        {title: 'Image Right', selector: 'img', styles: {
                          'float' : 'right',
                          'margin': '0 10px 0 10px'
                        }}
                    ],
                    //images_upload_base_path: '/some/basepath',
                    skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
                    content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default'),
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #242628; }',
                    height: 500,
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    // toolbar: 'code undo redo blocks ' +
                    //     'bold italic forecolor alignleft aligncenter ' +
                    //     'alignright alignjustify bullist numlist outdent indent ' +
                    //     'image removeformat help',
                        toolbar: 'code undo redo bold italic underline strikethrough fontfamily fontsize blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media template link anchor codesample ltr rtl',
                        // toolbar_sticky: true,
                        // toolbar_sticky_offset: 102,
                }}
            />
            <ListagemArquivos arquivos={arquivos} endpoint="blocos.php"/>
        </div>
    )
}

export default BlocoEditor;