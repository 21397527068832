import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider, AuthContext } from './contextos/Auth';

//paginas publicas.
import Agenda from './paginas/Agenda';
import AgendaView from './paginas/AgendaView';
import Contato from './paginas/Contato';
import ErrorPage from './paginas/ErrorPage';
import Home from './paginas/Home';
import News from './paginas/News';
import NewsView from './paginas/NewsView';
import Pagina from './paginas/Pagina';
import Repertorio from './paginas/Repertorio';

//paginas do admin.
import Banners from './paginas/Admin/Banners';
import Categorias from './paginas/Admin/Categorias';
import Cidades from './paginas/Admin/Cidades';
import Clientes from './paginas/Admin/Clientes';
import Contatos from './paginas/Admin/Contatos';
import Mensagens from './paginas/Admin/Mensagens';
import MenuAdmin from './paginas/Admin/Menu/MenuAdmin';
import MenuDrop from './paginas/Admin/Menu/MenuDrop';
import MenuGestor from './paginas/Admin/Menu/MenuGestor';
import MenuUsuario from './paginas/Admin/Menu/MenuUsuario';
import Musicas from './paginas/Admin/Musicas';
import Noticias from './paginas/Admin/Noticias';
import NoticiasPreview from './paginas/Admin/NoticiasPreview';
import Paginas from './paginas/Admin/Paginas';
import PaginasHits from './paginas/Admin/PaginasHits';
import Parametros from './paginas/Admin/Parametros';
import Shows from './paginas/Admin/Shows';
import ShowsSetlist from './paginas/Admin/ShowsSetlist';
import Usuarios from './paginas/Admin/Usuarios';
import Zaps from './paginas/Admin/Zaps';

//paginas do usuario.
import AlterarSenha from './paginas/Usuario/AlterarSenha';
import CriarConta from './paginas/Usuario/CriarConta';
import Confirmar from './paginas/Usuario/Confirmar';
import Login from './paginas/Usuario/Login';
import Profile from './paginas/Usuario/Profile';
import ResetSenha from './paginas/Usuario/Reset';

import "primereact/resources/themes/mdc-dark-deeppurple/theme.css";  //theme
import "primereact/resources/primereact.min.css";                    //core css
import "primeicons/primeicons.css";                                  //icons
import "./App.css"; //pett

function App() {
    const Privado = ({ children }) => {
        const { logado, usuarioStatus } = useContext(AuthContext);
                
        if (usuarioStatus==='I') {
            return <Navigate to='/confirmar' />
        }
        
        if (!logado) {
            return <Navigate to="/login" replace={true}/>
        }
        
        return children;
    };

    const Admin = ({ children }) => {
        const { usuarioAdmin } = useContext(AuthContext);

        if (usuarioAdmin==="N") {
            return <Navigate to="/" replace={true} />
        }

        return children;
    }

    const Gestor = ({ children }) => {
        const { usuarioGestor } = useContext(AuthContext);

        if (usuarioGestor==="N") {
            return <Navigate to="/" replace={true} />
        }

        return children;
    }

    const Carregando = ({children}) => {
        const { carregando } = useContext(AuthContext);
        if (carregando) {
            return <>
                <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '7em'}} className="text-muted"><i className="fa fa-fw fa-spin fa-sync-alt"></i></span><br/>Carregando...</p>
                </div>  
            </>
        }
        return children;
    }

    return (
        <Router>
            <AuthProvider>
                <Carregando>
                    <Routes>
                        <Route exact path='/' element={<Home />} /> 
                        <Route exact path='/agenda' element={<Agenda />} /> 
                        <Route exact path='/agenda/:id' element={<AgendaView />} /> 
                        <Route exact path='/cadastro' element={<CriarConta />} />
                        <Route exact path='/confirmar' element={<Confirmar />} />
                        <Route exact path='/contato' element={<Contato />} />
                        <Route exact path='/login' element={<Login />} /> 
                        <Route exact path='/:id' element={<Pagina />} /> 
                        <Route exact path='/news' element={<News />} /> 
                        <Route exact path='/news/:slug' element={<NewsView />} /> 
                        <Route exact path='/news/cat/:cat' element={<News />} /> 
                        <Route exact path='/news/ano/:ano' element={<News />} /> 
                        <Route exact path='/news/ano/:ano/:mes' element={<News />} /> 
                        <Route exact path='/news/tag/:tag' element={<News />} /> 
                        <Route exact path='/reset' element={<ResetSenha />} /> 
                        <Route exact path='/repertorio' element={<Repertorio />} /> 
                        
                        <Route exact path='/alterarsenha' element={<Privado><AlterarSenha /></Privado>} /> 
                        <Route exact path='/profile' element={<Privado><Profile /></Privado>} /> 

                        <Route exact path='/admin/banners' element={<Privado><Admin><Banners /></Admin></Privado>} /> 
                        <Route exact path='/admin/categorias' element={<Privado><Admin><Categorias /></Admin></Privado>} /> 
                        <Route exact path='/admin/cidades' element={<Privado><Admin><Cidades /></Admin></Privado>} /> 
                        <Route exact path='/admin/clientes' element={<Privado><Admin><Clientes /></Admin></Privado>} /> 
                        <Route exact path='/admin/contatos' element={<Privado><Admin><Contatos /></Admin></Privado>} /> 
                        <Route exact path='/admin/mensagens' element={<Privado><Admin><Mensagens /></Admin></Privado>} /> 
                        <Route exact path='/admin/menuadmin' element={<Privado><Admin><MenuAdmin /></Admin></Privado>} /> 
                        <Route exact path='/admin/menudrop' element={<Privado><Admin><MenuDrop /></Admin></Privado>} /> 
                        <Route exact path='/admin/menugestor' element={<Privado><Admin><MenuGestor /></Admin></Privado>} /> 
                        <Route exact path='/admin/menuusuario' element={<Privado><Admin><MenuUsuario /></Admin></Privado>} /> 
                        <Route exact path='/admin/musicas' element={<Privado><Admin><Musicas /></Admin></Privado>} /> 
                        <Route exact path='/admin/noticias' element={<Privado><Admin><Noticias /></Admin></Privado>} /> 
                        <Route exact path='/admin/noticiaspreview/:slug' element={<Privado><Admin><NoticiasPreview /></Admin></Privado>} /> 
                        <Route exact path='/admin/paginas' element={<Privado><Admin><Paginas /></Admin></Privado>} /> 
                        <Route exact path='/admin/paginashits' element={<Privado><Admin><PaginasHits /></Admin></Privado>} /> 
                        <Route exact path='/admin/paginashits/:id' element={<Privado><Admin><PaginasHits /></Admin></Privado>} /> 
                        <Route exact path='/admin/parametros' element={<Privado><Admin><Parametros /></Admin></Privado>} /> 
                        <Route exact path='/admin/shows' element={<Privado><Gestor><Shows /></Gestor></Privado>} /> 
                        <Route exact path='/admin/shows/:id' element={<Privado><Gestor><ShowsSetlist /></Gestor></Privado>} /> 
                        <Route exact path='/admin/usuarios' element={<Privado><Admin><Usuarios /></Admin></Privado>} /> 
                        <Route exact path='/admin/zaps' element={<Privado><Admin><Zaps /></Admin></Privado>} /> 

                        {/* <Route exact path='/' element={<Navigate replace to="/home" />} />  */}
                        <Route path='*' element={<ErrorPage />} />
                    </Routes>
                </Carregando>
            </AuthProvider>
        </Router>    
    );
}

export default App;
