import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';
import Cabecalho from '../componentes/Cabecalho';
import ColunaCentral from '../componentes/ColunaCentral';
import IfComponent from '../componentes/IfComponent';
import Rodape from '../componentes/Rodape';


function Agenda() {
    const [ listagem, setListagem ] = useState([]);

    function listar() {
        var formData = new FormData();
        formData.set('op', 'listar');       
        Api.post('agenda.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setListagem(response.data.msg);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });            
    }

    useEffect(() => {
        listar();
    },[]);

    return <>
        <BarraSuperior/>
        <ColunaCentral left={false} right={false}>
            <Cabecalho titulo="Agenda" texto=""/>
            <IfComponent condicional={listagem.length > 0}>
            <div className="container m-0 p-0 g-0 mb-3">
                    {                     
                        listagem.map((item,idx) => (
                            <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={item.SHO_CODIGO}>
                                <div className="row g-0">
                                    <div className="col-12 text-truncate d-flex justify-content-between p-1">
                                        
                                        <IfComponent condicional={item.SHO_STATUS==="A"}>
                                            <div className="bg-success px-3 py-0 m-0 g-0 d-flex flex-column text-white justify-content-center rounded-1">
                                                <span className="fs-1 p-0 m-0 lh-1 fw-bold" style={{fontFamily: "BebasNeue"}}>{item.SHO_DIA}</span>
                                                <span className="fs-6 m-0 p-0 lh-1 fw-bold text-center text-uppercase" style={{fontFamily: "Khand"}}>{item.SHO_MES}</span>
                                            </div>
                                            <div className="w-100 d-flex flex-column justify-content-between">
                                                <span className="text-truncate w-100 m-2 fs-6">{item.SHO_DESCRICAO}</span>
                                                <span className="mx-2 text-end mb-2">{item.SHO_PUBLICO==="S"?<span className="badge bg-success">PUBLICO</span>:<span className="badge bg-primary">PRIVADO</span>}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <a href={'/agenda/'+item.SHO_CODIGO} className="link-secondary stretched-link"><i className="fa fa-fw fa-chevron-right"></i></a>
                                            </div>
                                        </IfComponent>

                                        <IfComponent condicional={item.SHO_STATUS==="R"}>
                                            <div className="bg-warning px-3 py-0 m-0 g-0 d-flex flex-column text-white justify-content-center rounded-1">
                                                <span className="fs-1 p-0 m-0 lh-1 fw-bold" style={{fontFamily: "BebasNeue"}}>{item.SHO_DIA}</span>
                                                <span className="fs-6 m-0 p-0 lh-1 fw-bold text-center text-uppercase" style={{fontFamily: "Khand"}}>{item.SHO_MES}</span>
                                            </div>
                                            <div className="w-100 d-flex flex-column justify-content-between">
                                                <span className="text-truncate w-100 m-2 fs-6">{item.SHO_DESCRICAO}</span>
                                                <span className="mx-2 text-end mb-2">{item.SHO_PUBLICO==="S"?<span className="badge bg-success">PUBLICO</span>:<span className="badge bg-primary">PRIVADO</span>}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <a href={'/agenda/'+item.SHO_CODIGO} className="link-secondary stretched-link"><i className="fa fa-fw fa-chevron-right"></i></a>
                                            </div>
                                        </IfComponent>
                                        
                                        <IfComponent condicional={item.SHO_STATUS==="C"}>
                                            <div className="bg-danger px-3 py-0 m-0 g-0 d-flex flex-column text-white justify-content-center rounded-1">
                                                <span className="fs-1 p-0 m-0 lh-1 fw-bold text-decoration-line-through" style={{fontFamily: "BebasNeue"}}>{item.SHO_DIA}</span>
                                                <span className="fs-6 m-0 p-0 lh-1 fw-bold text-center text-uppercase text-decoration-line-through" style={{fontFamily: "Khand"}}>{item.SHO_MES}</span>
                                            </div>
                                            <div className="w-100 d-flex flex-column justify-content-between">
                                                <span className="text-truncate text-decoration-line-through w-100 m-2 fs-6">{item.SHO_DESCRICAO}</span>
                                                <span className="mx-2 text-end mb-2">{item.SHO_PUBLICO==="S"?<span className="badge bg-success">PUBLICO</span>:<span className="badge bg-primary">PRIVADO</span>}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <a href={'/agenda/'+item.SHO_CODIGO} className="link-secondary stretched-link"><i className="fa fa-fw fa-chevron-right"></i></a>
                                            </div>
                                        </IfComponent>

                                    </div>
                                </div>
                            </div>
                        ))
                    }   
                </div>                
            </IfComponent>
        </ColunaCentral>
        <Rodape/>
    </>
}

export default Agenda;