import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';
import Cabecalho from '../componentes/Cabecalho';
import ColunaCentral from '../componentes/ColunaCentral';
import IfComponent from '../componentes/IfComponent';
import Rodape from '../componentes/Rodape';

function AgendaView() {
    const { id } = useParams();
    const [ show, setShow ] = useState([]);
    const [ setlist, setSetlist ] = useState([]);

    function verShow() {
        var formData = new FormData();
        formData.set('op','ver');       
        formData.set('show',id);       
        Api.post('agenda.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setShow(response.data.msg);
                setSetlist(response.data.msg.SETLIST);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });            
    }

    useEffect(() => {
        verShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id]);

    return <>
        <BarraSuperior/>
        <ColunaCentral left={false} right={false}>
            <Cabecalho titulo={show.SHO_DESCRICAO} texto=""/>

            <div className="d-flex justify-content-between">
                <span>
                    Data: {show.SHO_DATA}<br/>
                    Hora: {show.SHO_HORA}<br/>
                    Tempo do setlist: {show.SHO_TEMPOTOTAL}<br/><br/>
                </span>
            </div>
            <div className="box shadow-sm">
                <a className="btn btn-sm btn-primary me-2" href={'/api/setlistpdf.php?show='+show.SHO_CODIGO} target="_blank" rel="noreferrer"><i className="fa fa-fw fa-print"></i> Imprimir Setlist</a>
            </div>


            <IfComponent condicional={setlist.length > 0}>
                <div className="container m-0 p-0 g-0 mb-3">
                    {
                        setlist.map((item,idx) => (
                            <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={idx}>
                                <div className="row g-0">
                                    <div className="col-12 pt-1 px-2 lh-2 text-truncate">
                                        <a href={item.MUS_LINK} className="link-preto stretched-link fw-bold">{item.MUS_NOME}</a>
                                    </div>
                                    <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small d-flex justify-content-between">
                                        <span>{idx+1+' - '}{item.MUS_TIPO==="N"?"NACIONAL":"INTERNACIONAL"}</span><span>Duração: {item.MUS_TEMPO}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                {/* <div className="container m-0 p-0 g-0 mb-3">
                    {                     
                        setlist.map((item,idx) => (

                            <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={item.SHO_CODIGO}>
                                <div className="row g-0">
                                    <div className="col-12 text-truncate d-flex justify-content-between p-1">
                                                                                
                                        <IfComponent condicional={item.SHO_STATUS==="C"}>
                                            <div className="bg-danger px-3 py-0 m-0 g-0 d-flex flex-column text-white justify-content-center rounded-1">
                                                <span className="fs-1 p-0 m-0 lh-1 fw-bold text-decoration-line-through" style={{fontFamily: "BebasNeue"}}>{item.SHO_DIA}</span>
                                                <span className="fs-6 m-0 p-0 lh-1 fw-bold text-center text-uppercase text-decoration-line-through" style={{fontFamily: "Khand"}}>{item.SHO_MES}</span>
                                            </div>
                                            <div className="w-100 d-flex flex-column justify-content-between">
                                                <span className="text-truncate text-decoration-line-through w-100 m-2 fs-6">{item.SHO_DESCRICAO}</span>
                                                <span className="mx-2 text-end mb-2">{item.SHO_PUBLICO==="S"?<span className="badge bg-success">PUBLICO</span>:<span className="badge bg-primary">PRIVADO</span>}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <a href={'/agenda/'+item.SHO_CODIGO} className="link-secondary stretched-link"><i className="fa fa-fw fa-chevron-right"></i></a>
                                            </div>
                                        </IfComponent>

                                    </div>
                                </div>
                            </div>
                        ))
                    }   
                </div>                 */}
            </IfComponent>
        </ColunaCentral>
        <Rodape/>
    </>
}

export default AgendaView;