import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import Api from '../Api';
import Cabecalho from './Cabecalho';
import IfComponent from './IfComponent';
import ErrorPage from '../paginas/ErrorPage';

function Bloco(props) {
    const [ header, setHeader ] = useState('');
    const [ bloco, setBloco ] = useState('');
    const [ encontrado, setEncontrado ] = useState(true);
    const { id, pagina, titulo, texto } = props;

    function verBloco() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', id);
        formData.set('pagina', pagina);
        
        Api.post('paginas.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setBloco(response.data.msg.BLO_CONTEUDO);
                setHeader(response.data.msg.BLO_NOME);
                setEncontrado(true);
            } else {
                setEncontrado(false);
                //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });            
    }

    useEffect(() => {
        verBloco();    
        // eslint-disable-next-line
    },[id, pagina, titulo, texto]);

    return <>
        <IfComponent condicional={encontrado===true}>
            <IfComponent condicional={titulo===true}>
                <Cabecalho titulo={header} texto={texto}/>
            </IfComponent>
            <section className="text-light" dangerouslySetInnerHTML={{ __html: bloco }} />
        </IfComponent>
        <IfComponent condicional={encontrado===false}>
            <ErrorPage/>
        </IfComponent>
    </>
}

export default Bloco;