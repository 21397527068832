import React, { useContext }  from 'react';
// import { Link } from 'react-router-dom';
import { AuthContext } from '../contextos/Auth';

import IfComponent from './IfComponent';

function Perfil() {
    const { logado, logout, usuarioAvatar, usuarioNome, usuarioLogin } = useContext(AuthContext);
    return <>
        <IfComponent condicional={logado===true}>
            <div className="card mb-2 w-100 bg-secondary bg-opacity-10 border-0">
                <div className="row g-0">
                    <div className="col-2">
                        <img src={usuarioAvatar} className="img-fluid rounded" alt="..." style={{maxHeight: '60px'}} />
                    </div>
                    <div className="col-10">
                        <div className="card-body p-2">
                            <h5 className="card-title text-truncate m-0 p-0 fw-bold text-light">{usuarioNome}</h5>
                            <p className="card-text text-truncate"><small className="text-muted">{usuarioLogin}</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-6 pe-1">
                    <a href="/profile" className="btn btn-sm btn-dark bg-secondary bg-opacity-10 text-light w-100"><i className="fa fa-fw fa-user"></i> Minha Conta</a>
                </div>
                <div className="col-6 ps-1">
                    <a href="/alterarsenha" className="btn btn-sm btn-dark bg-secondary bg-opacity-10 text-light w-100"><i className="fa fa-fw fa-lock"></i> Alterar Senha</a>
                </div>
                <div className="col-12 mt-2">
                    <button className="btn btn-sm btn-dark bg-secondary bg-opacity-10 text-light w-100" onClick={() => logout()}><i className="fa fa-fw fa-sign-out"></i> Sair</button>
                </div>
            </div>
            {/* <img src='http://www.solparagliders.com.br/profiles/1.jpg' className='rounded' alt='' style={{maxHeight: '55px'}}/>
            <a className="btn btn-sm btn-outline-dark" href="\#" onClick={logout}>Sair</a> */}
        </IfComponent>     
        <IfComponent condicional={logado===false}>
            <div className="row g-0">
                <div className="col-6 pe-1">
                    <a href="/login" className="btn btn-sm btn-dark bg-secondary bg-opacity-10 text-light w-100"><i className="fa fa-fw fa-sign-in"></i> Acessar</a>
                    {/* <Link to="/login" className="btn btn-sm btn-dark bg-secondary bg-opacity-10 text-light w-100"><i className="fa fa-fw fa-sign-in"></i> Acessar</Link> */}
                </div>
                <div className="col-6 ps-1">
                    <a href="/usuario/cadastro" className="btn btn-sm btn-dark bg-secondary bg-opacity-10 text-light w-100"><i className="fa fa-fw fa-user-plus"></i> Criar Conta</a>
                    {/* <Link to="/usuario/cadastro" className="btn btn-sm btn-dark bg-secondary bg-opacity-10 text-light w-100"><i className="fa fa-fw fa-user-plus"></i> Criar Conta</Link> */}
                </div>
            </div>
        </IfComponent>     
    </>
}

export default Perfil;