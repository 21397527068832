import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import IfComponent from '../../componentes/IfComponent';
import Rodape from '../../componentes/Rodape';

function ShowsSetlist() {
    const { logout } = useContext(AuthContext);
    const { id } = useParams();
    const [ show, setShow ] = useState([]);
    const [ setlist, setSetlist ] = useState([]);
    const [ repertorio, setRepertorio ] = useState([]);
    const [ modalMusicas, setModalMusicas ] = useState(false);
    // const [ removendoExistentes, setRemovendoExistentes ] = useState(false);

    function verShow() {
        var formData = new FormData();
        formData.set('op','verset');
        formData.set('codigo',id);
        Api.post('admin/shows.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setShow(response.data.msg);
                setSetlist(response.data.msg.SETLIST);
                setRepertorio(response.data.msg.REPERTORIO);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });
    }

    // function getRepertorio() {
    //     var formData = new FormData();
    //     formData.set('op', 'listar');       
    //     Api.post('repertorio.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setRepertorio(response.data.msg);
    //         } else {
    //             Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
    //         }
    //     }).catch(({response}) => {
    //         Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
    //     });         
    // }
    // const getItems = (count) =>
    //     Array.from({ length: count }, (v, k) => k).map((k) => ({
    //         MUS_CODIGO: `item-${k}`,
    //         MUS_NOME: `item ${k}`,
    //         MUS_TIPO: `tipo ${k}`,
    //         MUS_TEMPO: `tempo ${k}`
    // }));

    useEffect(() => {
        verShow();
        // const n = getItems(8);
        // setSetlist(n);

        //getRepertorio();
        //removerExistentes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id]);

    // useEffect(() => {
    //     if (!removendoExistentes) {
    //         //removerExistentes();
    //     }
    // },[setlist,repertorio]);

    // function removerExistentes() {
    //     // setRemovendoExistentes(true);
    //     //remove do repertorio as músicas ja existentes no setlist;
    //     let novoRepertorio = [];
    //     setlist.map(list => {
    //         novoRepertorio = repertorio.filter(musica => String(musica.MUS_CODIGO !== String(list.MUS_CODIGO)))
    //     })
    //     setRepertorio(novoRepertorio);
    //     console.log('remover',novoRepertorio);
    //     // setRemovendoExistentes(false);
    // }

    function adicionarMusica(e,item) {
        e.preventDefault();
        let novaListagem = [];
        novaListagem = repertorio.filter(musica => (String(musica.MUS_CODIGO) !== String(item.MUS_CODIGO)));
        setRepertorio(novaListagem);

        const novoitem = {...item, 'ordem': 0};
        //let novoSetlist = [];
        setlist.unshift(novoitem);
        //removerExistentes();
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        //margin: `0 0 ${grid}px 0`,
      
        // change background colour if dragging
        background: isDragging ? "#000" : "#131313",
        
        // styles we need to apply on draggables
        ...draggableStyle
    });

    //const grid = 8;

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#2c3034" : "#131313",
        padding: 0,
        width: '100%'
    });

    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
           setlist,
           result.source.index,
           result.destination.index
        );
    
        setSetlist(items);
    }

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

    function salvarSetlist() {
        var formData = new FormData();
        formData.set('op','salvarset');
        formData.set('codigo',id);
        formData.set('setlist',JSON.stringify(setlist));
        Api.post('admin/shows.php',formData).then((response) => {
            if (response.data.erro==='N') {
                const novoShow = {...show, 'SHO_TEMPOTOTAL': response.data.tempo }
                setShow(novoShow);
                //console.log(response.data.tempo);
                Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });
    }

    function excluirMusica(codigo,nome) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','delmusica');
                formData.set('codigo',codigo);
                Api.post('admin/shows.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        const novaListagem = setlist.filter(item => (item.SET_CODIGO !== codigo));
                        setSetlist(novaListagem);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left={false} right={false}>
            <Cabecalho titulo={show.SHO_DESCRICAO} texto=""/>

            <div className="d-flex justify-content-between">
                <span className="w-100">
                    Data: <b>{show.SHO_DATA}</b><br/>
                    Hora: <b>{show.SHO_HORA}</b><br/>
                    Tempo do setlist: <b>{show.SHO_TEMPOTOTAL}</b><br/><br/>
                </span>
            </div>
            <div className="box shadow-sm">
                <a className="btn btn-sm btn-primary me-2" href={'/api/setlistpdf.php?show='+show.SHO_CODIGO} target="_blank" rel="noreferrer"><i className="fa fa-fw fa-print"></i> Imprimir Setlist</a>
                <button className="btn btn-sm btn-primary me-2" onClick={() => setModalMusicas(true)}><i className="fa fa-fw fa-plus"></i> Adicionar Música</button>
                <button className="btn btn-sm btn-success" onClick={() => salvarSetlist()}><i className="fa fa-fw fa-save"></i> Salvar</button>
            </div>

            <IfComponent condicional={setlist.length > 0}>
                <div className="container m-0 p-0 g-0 mb-3">
                    {/* {
                        setlist.map((item,idx) => (
                            <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={idx}>
                                <div className="row g-0">
                                    <div className="col-12 pt-1 px-2 lh-2 text-truncate">
                                        <a href={item.MUS_LINK} className="link-preto stretched-link fw-bold">{item.MUS_NOME}</a>
                                    </div>
                                    <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small d-flex justify-content-between">
                                        <span>{idx+1+' - '}{item.MUS_TIPO==="N"?"NACIONAL":"INTERNACIONAL"}</span><span>Duração: {item.MUS_TEMPO}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    } */}

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                    {setlist.map((item, index) => (
                                        <Draggable key={item.MUS_CODIGO} draggableId={String(item.MUS_CODIGO)} index={index}  className="w-100">
                                            {(provided, snapshot) => (
                                                <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}>
                                                    {/* {item.MUS_NOME} */}
                                                {/* </div> */}
                                                {/* <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={idx}> */}
                                                    <div className="row g-0">
                                                        <div className="col-12 pt-1 px-2 lh-2 text-truncate fw-bold">
                                                            {item.MUS_NOME}
                                                        </div>
                                                        <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small d-flex justify-content-between">
                                                            <span>{index+1+' - '}{item.MUS_TIPO==="N"?"NACIONAL":"INTERNACIONAL"}</span><span>Duração: {item.MUS_TEMPO} <button className="btn btn-sm btn-outline-danger ms-3" onClick={() => excluirMusica(item.SET_CODIGO,item.MUS_NOME)}><i className="fa fa-fw fa-trash"></i></button></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                              
                        </Droppable>
                    </DragDropContext>

                </div>

                {/* <div className="container m-0 p-0 g-0 mb-3">
                    {                     
                        setlist.map((item,idx) => (

                            <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={item.SHO_CODIGO}>
                                <div className="row g-0">
                                    <div className="col-12 text-truncate d-flex justify-content-between p-1">
                                                                                
                                        <IfComponent condicional={item.SHO_STATUS==="C"}>
                                            <div className="bg-danger px-3 py-0 m-0 g-0 d-flex flex-column text-white justify-content-center rounded-1">
                                                <span className="fs-1 p-0 m-0 lh-1 fw-bold text-decoration-line-through" style={{fontFamily: "BebasNeue"}}>{item.SHO_DIA}</span>
                                                <span className="fs-6 m-0 p-0 lh-1 fw-bold text-center text-uppercase text-decoration-line-through" style={{fontFamily: "Khand"}}>{item.SHO_MES}</span>
                                            </div>
                                            <div className="w-100 d-flex flex-column justify-content-between">
                                                <span className="text-truncate text-decoration-line-through w-100 m-2 fs-6">{item.SHO_DESCRICAO}</span>
                                                <span className="mx-2 text-end mb-2">{item.SHO_PUBLICO==="S"?<span className="badge bg-success">PUBLICO</span>:<span className="badge bg-primary">PRIVADO</span>}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <a href={'/agenda/'+item.SHO_CODIGO} className="link-secondary stretched-link"><i className="fa fa-fw fa-chevron-right"></i></a>
                                            </div>
                                        </IfComponent>

                                    </div>
                                </div>
                            </div>
                        ))
                    }   
                </div>                 */}
            </IfComponent>

            <Modal show={modalMusicas} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-color2 text-light py-0 limit-height border-bottom border-dark">
                <Modal.Title><h5 className="modal-title">REPERTÓRIO</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-color1">
                <IfComponent condicional={repertorio.length > 0}>
                    <div className="container-fluid m-0 p-0 g-0 mb-3">
                        {                     
                            repertorio.map((item,idx) => (
                                <div className="card rounded-0 border-0 border-bottom border-dark w-100 bg-color2" key={item.MUS_CODIGO}>
                                    <div className="row g-0">
                                        <div className="col-12 pt-1 px-2 lh-2 text-truncate">
                                            <a href="\#" className="stretched-link fw-bold link-light" onClick={(e) => adicionarMusica(e,item)}>{item.MUS_NOME}</a>
                                        </div>
                                        <div className="col-12 pb-1 px-2 lh-2 text-truncate text-muted small d-flex justify-content-between">
                                            <span>{idx+1+' - '}{item.MUS_TIPO==="N"?"NACIONAL":"INTERNACIONAL"}</span><span>Duração: {item.MUS_TEMPO}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }   
                    </div>
                </IfComponent>
                <IfComponent condicional={repertorio.length===0}>
                    <div className="container d-flex justify-content-center align-items-center">
                        <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-music"></i></span><br/>Nenhuma música disponivel!</p>
                    </div>  
                </IfComponent>
            </Modal.Body>

            <Modal.Footer className='bg-color2 border-top border-dark text-light py-0 limit-height'>
                <button className="btn btn-secondary" onClick={() => setModalMusicas(false)}>Fechar</button>
                {/* <Button variant="success"><i className="fa fa-fw fa-check"></i> Salvar</Button> */}
            </Modal.Footer>
        </Modal>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default ShowsSetlist;