import React, { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
import { Editor } from '@tinymce/tinymce-react';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import IfComponent from '../../componentes/IfComponent';
import Rodape from '../../componentes/Rodape';

function Parametros() {
    const { logout } = useContext(AuthContext);
    const referenciaEditor1 = useRef(null);
    const referenciaEditor2 = useRef(null);
    const referenciaEditor3 = useRef(null);
    const referenciaEditor4 = useRef(null);
    const referenciaEditor5 = useRef(null);
    const [ parametros, setParametros ] = useState({});

    async function getParametros() {
        const formData = new FormData();
        formData.set('op','ver');
        const response = await Api.post('admin/parametros.php',formData);
        if (response.data.erro==='N') {
            setParametros(response.data.msg);
        }
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    // const getUnidades = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','combo');
    //     Api.post('unidades.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setUnidades(response.data.msg);
    //         }
    //     });
    // },[]);
    async function salvar() {
        const formData = new FormData();
        formData.set('op','atualizar');
        formData.set('PAR_FACEBOOK',parametros.PAR_FACEBOOK || '');
        formData.set('PAR_INSTAGRAM',parametros.PAR_INSTAGRAM || '');
        formData.set('PAR_MAILNAME',parametros.PAR_MAILNAME || '');
        formData.set('PAR_MAILFROM',parametros.PAR_MAILFROM || '');
        formData.set('PAR_MAILUSER',parametros.PAR_MAILUSER || '');
        formData.set('PAR_MAILPASS',parametros.PAR_MAILPASS || '');
        formData.set('PAR_MAILSMTP',parametros.PAR_MAILSMTP || '');
        formData.set('PAR_MAILPORT',parametros.PAR_MAILPORT || '0');
        formData.set('PAR_MAILADMIN',parametros.PAR_MAILADMIN || '');
        formData.set('PAR_MAILPREFIX',parametros.PAR_MAILPREFIX || '');
        formData.set('PAR_SITENAME',parametros.PAR_SITENAME || '');
        formData.set('PAR_WHATSAPP',parametros.PAR_WHATSAPP || '');
        formData.set('PAR_YOUTUBE',parametros.PAR_YOUTUBE || '');
        formData.set('PAR_EMAILNOVOUSUARIO',referenciaEditor1.current.getContent());
        formData.set('PAR_EMAILRESETARSENHA',referenciaEditor2.current.getContent());
        formData.set('PAR_EMAILALTERAREMAIL',referenciaEditor3.current.getContent());
        formData.set('PAR_EMAILUSUARIOCONFIRMADO',referenciaEditor4.current.getContent());
        formData.set('PAR_EMAILCONTATO',referenciaEditor5.current.getContent());

        const response = await Api.post('/admin/parametros.php',formData);
        if (response.data.erro==='N') {
            setParametros(response.data.msg);
            Swal.fire({title: 'Yessss!', text: 'Parâmetros atualizados com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
        } else {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    useEffect(() => {
        getParametros();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="parametros") {
            setParametros({...parametros, [name]: valor});
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral>
            <Cabecalho titulo='Parâmetros' texto=''/>
            <IfComponent condicional={parametros.PAR_CODIGO==="1"}>

                <div className="accordion mb-3" id="accordionExample">

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSite"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSite" aria-expanded="true" aria-controls="collapseSite">Geral</button></h2>
                        <div className="accordion-collapse collapse show" id="collapseSite" aria-labelledby="headingSite" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-1 p-inputtext-sm pett-input">
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_SITENAME" value={parametros.PAR_SITENAME || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">TÍTULO DO SITE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILADMIN" value={parametros.PAR_MAILADMIN || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">EMAIL DO ADMINISTRADOR</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILPREFIX" value={parametros.PAR_MAILPREFIX || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">PREFIXO DO ASSUNTO DO EMAIL</label>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingRedes"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRedes" aria-expanded="true" aria-controls="collapseRedes">Redes Sociais</button></h2>
                        <div className="accordion-collapse collapse" id="collapseRedes" aria-labelledby="headingSite" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-1 p-inputtext-sm pett-input">
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_FACEBOOK" value={parametros.PAR_FACEBOOK || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">FACEBOOK</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_INSTAGRAM" value={parametros.PAR_INSTAGRAM || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">INSTAGRAM</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_YOUTUBE" value={parametros.PAR_YOUTUBE || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">YOUTUBE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_WHATSAPP" value={parametros.PAR_WHATSAPP || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">WHATSAPP</label>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingMail"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMail" aria-expanded="true" aria-controls="collapseMail">Configuração da conta de email</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseMail" aria-labelledby="headingMail" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-1 p-inputtext-sm pett-input">
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILNAME" value={parametros.PAR_MAILNAME || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">NOME DO REMETENTE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILFROM" value={parametros.PAR_MAILFROM || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">EMAIL DO REMETENTE</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILUSER" value={parametros.PAR_MAILUSER || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">LOGIN</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILPASS" value={parametros.PAR_MAILPASS || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">SENHA DO EMAIL</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="100" name="PAR_MAILSMTP" value={parametros.PAR_MAILSMTP || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">SERVIDOR SMTP</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <span className="p-float-label pett-label">
                                            <InputText className="w-100" maxLength="10" name="PAR_MAILPORT" value={parametros.PAR_MAILPORT || ''} onChange={(e) => setCampo(e,'parametros',false)} />
                                            <label htmlFor="texto">PORTA</label>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNovoUsuario"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNovoUsuario" aria-expanded="true" aria-controls="collapseNovoUsuario">Email para o novo usuário</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseNovoUsuario" aria-labelledby="headingNovoUsuario" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <Editor
                apiKey='ly6hr1bka7s3t7ud9o2omflzzdmrx4ydmpkvqns8rj5c4i6b'
                onInit={(evt, editor) => referenciaEditor1.current = editor}
                //onInit={(evt, editor) => editorRef.current = editor} //passamos a referencia por props para ter acesso em paginas.jsx
                initialValue={parametros.PAR_EMAILNOVOUSUARIO}
                init={{
                    paste_as_text: true,
                    relative_urls: false,
                    //remove_script_host: false,
                    //convert_urls: true, //https://www.tiny.cloud/docs/configure/url-handling/
                    document_base_url: 'https://www.runtimesistemas.com.br',
                    images_upload_url: process.env.REACT_APP_URL_API + '/admin/noticiaAcceptor.php',
                    // images_upload_handler: imageUploadHandler,
                    images_upload_credentials: true,
                    //image_prepend_url: 'https://www.runtimesistemas.com.br/',
                    //typeahead_urls: false,
                    image_advtab: true,
                    image_dimensions: true,
                    image_title: true,
                    image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'img-responsive', value: 'img-responsive'},
                        {title: 'img-thumbnail', value: 'img-thumbnail'}
                    ],
                    style_formats: [
                        {title: 'Image Left', selector: 'img', styles: {
                          'float' : 'left',
                          'margin': '0 10px 0 10px'
                        }},
                        {title: 'Image Right', selector: 'img', styles: {
                          'float' : 'right',
                          'margin': '0 10px 0 10px'
                        }}
                    ],
                    //images_upload_base_path: '/some/basepath',
                    skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
                    content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default'),
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #242628; }',
                    height: 500,
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    // toolbar: 'code undo redo blocks ' +
                    //     'bold italic forecolor alignleft aligncenter ' +
                    //     'alignright alignjustify bullist numlist outdent indent ' +
                    //     'image removeformat help',
                    toolbar: 'code undo redo bold italic underline strikethrough fontfamily fontsize blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media template link anchor codesample ltr rtl',
                    // toolbar_sticky: true,
                    // toolbar_sticky_offset: 102,
                }}
            />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingResetarSenha"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseResetarSenha" aria-expanded="true" aria-controls="collapseResetarSenha">Email Resetar Senha</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseResetarSenha" aria-labelledby="headingResetarSenha" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <Editor
                apiKey='ly6hr1bka7s3t7ud9o2omflzzdmrx4ydmpkvqns8rj5c4i6b'
                onInit={(evt, editor) => referenciaEditor2.current = editor}
                //onInit={(evt, editor) => editorRef.current = editor} //passamos a referencia por props para ter acesso em paginas.jsx
                initialValue={parametros.PAR_EMAILRESETARSENHA}
                init={{
                    paste_as_text: true,
                    relative_urls: false,
                    //remove_script_host: false,
                    //convert_urls: true, //https://www.tiny.cloud/docs/configure/url-handling/
                    document_base_url: 'https://www.runtimesistemas.com.br',
                    images_upload_url: process.env.REACT_APP_URL_API + '/admin/noticiaAcceptor.php',
                    // images_upload_handler: imageUploadHandler,
                    images_upload_credentials: true,
                    //image_prepend_url: 'https://www.runtimesistemas.com.br/',
                    //typeahead_urls: false,
                    image_advtab: true,
                    image_dimensions: true,
                    image_title: true,
                    image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'img-responsive', value: 'img-responsive'},
                        {title: 'img-thumbnail', value: 'img-thumbnail'}
                    ],
                    style_formats: [
                        {title: 'Image Left', selector: 'img', styles: {
                          'float' : 'left',
                          'margin': '0 10px 0 10px'
                        }},
                        {title: 'Image Right', selector: 'img', styles: {
                          'float' : 'right',
                          'margin': '0 10px 0 10px'
                        }}
                    ],
                    //images_upload_base_path: '/some/basepath',
                    skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
                    content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default'),
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #242628; }',
                    height: 500,
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    // toolbar: 'code undo redo blocks ' +
                    //     'bold italic forecolor alignleft aligncenter ' +
                    //     'alignright alignjustify bullist numlist outdent indent ' +
                    //     'image removeformat help',
                    toolbar: 'code undo redo bold italic underline strikethrough fontfamily fontsize blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media template link anchor codesample ltr rtl',
                    // toolbar_sticky: true,
                    // toolbar_sticky_offset: 102,
                }}
            />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingAlterarEmail"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAlterarEmail" aria-expanded="true" aria-controls="collapseAlterarEmail">Email Alterar Email</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseAlterarEmail" aria-labelledby="headingAlterarEmail" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <Editor
                apiKey='ly6hr1bka7s3t7ud9o2omflzzdmrx4ydmpkvqns8rj5c4i6b'
                onInit={(evt, editor) => referenciaEditor3.current = editor}
                //onInit={(evt, editor) => editorRef.current = editor} //passamos a referencia por props para ter acesso em paginas.jsx
                initialValue={parametros.PAR_EMAILALTERAREMAIL}
                init={{
                    paste_as_text: true,
                    relative_urls: false,
                    //remove_script_host: false,
                    //convert_urls: true, //https://www.tiny.cloud/docs/configure/url-handling/
                    document_base_url: 'https://www.runtimesistemas.com.br',
                    images_upload_url: process.env.REACT_APP_URL_API + '/admin/noticiaAcceptor.php',
                    // images_upload_handler: imageUploadHandler,
                    images_upload_credentials: true,
                    //image_prepend_url: 'https://www.runtimesistemas.com.br/',
                    //typeahead_urls: false,
                    image_advtab: true,
                    image_dimensions: true,
                    image_title: true,
                    image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'img-responsive', value: 'img-responsive'},
                        {title: 'img-thumbnail', value: 'img-thumbnail'}
                    ],
                    style_formats: [
                        {title: 'Image Left', selector: 'img', styles: {
                          'float' : 'left',
                          'margin': '0 10px 0 10px'
                        }},
                        {title: 'Image Right', selector: 'img', styles: {
                          'float' : 'right',
                          'margin': '0 10px 0 10px'
                        }}
                    ],
                    //images_upload_base_path: '/some/basepath',
                    skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
                    content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default'),
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #242628; }',
                    height: 500,
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    // toolbar: 'code undo redo blocks ' +
                    //     'bold italic forecolor alignleft aligncenter ' +
                    //     'alignright alignjustify bullist numlist outdent indent ' +
                    //     'image removeformat help',
                    toolbar: 'code undo redo bold italic underline strikethrough fontfamily fontsize blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media template link anchor codesample ltr rtl',
                    // toolbar_sticky: true,
                    // toolbar_sticky_offset: 102,
                }}
            />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingUsuarioConfirmado"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUsuarioConfirmado" aria-expanded="true" aria-controls="collapseUsuarioConfirmado">Email Usuário Confirmado</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseUsuarioConfirmado" aria-labelledby="headingUsuarioConfirmado" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <Editor
                apiKey='ly6hr1bka7s3t7ud9o2omflzzdmrx4ydmpkvqns8rj5c4i6b'
                onInit={(evt, editor) => referenciaEditor4.current = editor}
                //onInit={(evt, editor) => editorRef.current = editor} //passamos a referencia por props para ter acesso em paginas.jsx
                initialValue={parametros.PAR_EMAILUSUARIOCONFIRMADO}
                init={{
                    paste_as_text: true,
                    relative_urls: false,
                    //remove_script_host: false,
                    //convert_urls: true, //https://www.tiny.cloud/docs/configure/url-handling/
                    document_base_url: 'https://www.runtimesistemas.com.br',
                    images_upload_url: process.env.REACT_APP_URL_API + '/admin/noticiaAcceptor.php',
                    // images_upload_handler: imageUploadHandler,
                    images_upload_credentials: true,
                    //image_prepend_url: 'https://www.runtimesistemas.com.br/',
                    //typeahead_urls: false,
                    image_advtab: true,
                    image_dimensions: true,
                    image_title: true,
                    image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'img-responsive', value: 'img-responsive'},
                        {title: 'img-thumbnail', value: 'img-thumbnail'}
                    ],
                    style_formats: [
                        {title: 'Image Left', selector: 'img', styles: {
                          'float' : 'left',
                          'margin': '0 10px 0 10px'
                        }},
                        {title: 'Image Right', selector: 'img', styles: {
                          'float' : 'right',
                          'margin': '0 10px 0 10px'
                        }}
                    ],
                    //images_upload_base_path: '/some/basepath',
                    skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
                    content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default'),
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #242628; }',
                    height: 500,
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    // toolbar: 'code undo redo blocks ' +
                    //     'bold italic forecolor alignleft aligncenter ' +
                    //     'alignright alignjustify bullist numlist outdent indent ' +
                    //     'image removeformat help',
                    toolbar: 'code undo redo bold italic underline strikethrough fontfamily fontsize blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media template link anchor codesample ltr rtl',
                    // toolbar_sticky: true,
                    // toolbar_sticky_offset: 102,
                }}
            />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingContato"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseContato" aria-expanded="true" aria-controls="collapseContato">Email Formulário de Contato</button></h2>
                        <div className ="accordion-collapse collapse" id="collapseContato" aria-labelledby="headingContato" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <div className="row gx-2 p-inputtext-sm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-0 p-0">
                                        <Editor
                apiKey='ly6hr1bka7s3t7ud9o2omflzzdmrx4ydmpkvqns8rj5c4i6b'
                onInit={(evt, editor) => referenciaEditor5.current = editor}
                //onInit={(evt, editor) => editorRef.current = editor} //passamos a referencia por props para ter acesso em paginas.jsx
                initialValue={parametros.PAR_EMAILCONTATO}
                init={{
                    paste_as_text: true,
                    relative_urls: false,
                    //remove_script_host: false,
                    //convert_urls: true, //https://www.tiny.cloud/docs/configure/url-handling/
                    document_base_url: 'https://www.runtimesistemas.com.br',
                    images_upload_url: process.env.REACT_APP_URL_API + '/admin/noticiaAcceptor.php',
                    // images_upload_handler: imageUploadHandler,
                    images_upload_credentials: true,
                    //image_prepend_url: 'https://www.runtimesistemas.com.br/',
                    //typeahead_urls: false,
                    image_advtab: true,
                    image_dimensions: true,
                    image_title: true,
                    image_class_list: [
                        {title: 'None', value: ''},
                        {title: 'img-responsive', value: 'img-responsive'},
                        {title: 'img-thumbnail', value: 'img-thumbnail'}
                    ],
                    style_formats: [
                        {title: 'Image Left', selector: 'img', styles: {
                          'float' : 'left',
                          'margin': '0 10px 0 10px'
                        }},
                        {title: 'Image Right', selector: 'img', styles: {
                          'float' : 'right',
                          'margin': '0 10px 0 10px'
                        }}
                    ],
                    //images_upload_base_path: '/some/basepath',
                    skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
                    content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default'),
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #242628; }',
                    height: 500,
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    // toolbar: 'code undo redo blocks ' +
                    //     'bold italic forecolor alignleft aligncenter ' +
                    //     'alignright alignjustify bullist numlist outdent indent ' +
                    //     'image removeformat help',
                    toolbar: 'code undo redo bold italic underline strikethrough fontfamily fontsize blocks alignleft aligncenter alignright alignjustify outdent indent numlist bullist forecolor backcolor removeformat image media template link anchor codesample ltr rtl',
                    // toolbar_sticky: true,
                    // toolbar_sticky_offset: 102,
                }}
            />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar()}>Salvar</button>
                </div>
            </IfComponent>
            <IfComponent condicional={parametros.PAR_CODIGO!=='1'}>
                <p className="lead">Não foi possível carregar os parâmetros!</p>
            </IfComponent>
        </ColunaCentral>
        <Rodape/>
    </>
}

export default Parametros;