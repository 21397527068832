import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import Rodape from '../../componentes/Rodape';
import IfComponent from '../../componentes/IfComponent';

function Musicas() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ pesquisa, setPesquisa ] = useState({
        campo: 'MUS_NOME',
        texto: ''
    });
    const [ musica, setMusica ] = useState({
        MUS_CODIGO: '',
        MUS_LINK: '',
        MUS_NOME: '',
        MUS_STATUS: 'A',
        MUS_TEMPO: '',
        MUS_TEMPOSEG: '',
        MUS_TIPO: 'N',
    });
    const [ operacao, setOperacao ] = useState('listar');

    const SelectItemsTIPO = [
        {value: "MUS_CODIGO", label: "Código" },
        {value: "MUS_NOME", label: "Nome" }
    ];

    const SelectItemsTIPOMUSICA = [
        {value: "N", label: "NACIONAL" },
        {value: "I", label: "INTERNACIONAL" }
    ];

    const SelectItemsSTATUS = [
        {value: "A", label: "ATIVA" },
        {value: "I", label: "INATIVA" }
    ];

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
                formData.set('op', 'listar');
                formData.set('pagina', novaPagina);
                formData.set('campo', pesquisa.campo);
                formData.set('pesquisa', pesquisa.texto);
            Api.post('admin/musicas.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar) {
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        setOperacao('');

        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listar(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function pesquisar() {
        setPagina(0);
        setFinal(false);
        listar(0,false,true);
    };

    function reset() {
        window.location.reload();
        // const pesquisa = { campo: 'MUS_NOME', texto: '' };
        // //setPesquisa({...pesquisa, texto: ''});
        // setPesquisa(pesquisa);
        // setPagina(0);
        // setFinal(false);
        // listar(0,false,true);
    };
    
    function cancelar() {
        setOperacao('listar');
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="musica") {
            setMusica({...musica, [name]: valor});
        }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
        }
    }

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/musicas.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listar(0,false,true);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    function novo() {
        setMusica({
            MUS_CODIGO: '',
            MUS_LINK: '',
            MUS_NOME: '',
            MUS_STATUS: 'A',
            MUS_TEMPO: '',
            MUS_TEMPOSEG: '',
            MUS_TIPO: 'N',
        });
        setOperacao('inserir');
    }

    async function salvar() {
        const formData = new FormData();
        formData.set('op',operacao);
        formData.set('MUS_CODIGO',musica.MUS_CODIGO);
        formData.set('MUS_LINK',musica.MUS_LINK);
        formData.set('MUS_NOME',musica.MUS_NOME);
        formData.set('MUS_STATUS',musica.MUS_STATUS);
        formData.set('MUS_TEMPO',musica.MUS_TEMPO);
        formData.set('MUS_TIPO',musica.MUS_TIPO);
        const response = await Api.post('admin/musicas.php',formData);
        if (response.data.erro==='N') {
            let novaListagem = [];
            if (musica.MUS_CODIGO===0) { 
                //inserção.
                Swal.fire({title: 'Yessss!', text: 'Música cadastrada com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem.push(...listagem);
                novaListagem.push(response.data.msg);
            } else {
                //edição.
                Swal.fire({title: 'Yessss!', text: 'Música atualizada com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
                novaListagem = listagem.filter(item => (String(item.MUS_CODIGO) !== String(musica.MUS_CODIGO)));
                novaListagem.unshift(response.data.msg); //adiciona no inicio da listagem.
            }
            
            //ordena a listagem.
            const novaListagemSort = novaListagem.sort(function(c1,c2) {
                if (c1.MUS_NOME > c2.MUS_NOME) return  1;
                if (c1.MUS_NOME < c2.MUS_NOME) return -1;
                return 0;
            });
            
            setListagem(novaListagemSort);
            setOperacao('listar');
            setMusica({        
                MUS_CODIGO: 0,
                MUS_LINK: '',
                MUS_NOME: '',
                MUS_STATUS: 'A',
                MUS_TEMPO: '',
                MUS_TEMPOSEG: '',
                MUS_TIPO: 'N',
            });
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    function editar(codigo) {
        setOperacao('atualizar');
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', codigo);
        Api.post('admin/musicas.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setMusica(response.data.msg);
            } else {
                setOperacao('listar');
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH Editar Categoria');
        });        
    }

    async function setStatus(e,codigo) {
        const formData = new FormData();
        formData.set('op','status');
        formData.set('codigo',codigo);
        formData.set('status',e.value===true?'A':'I');
        const response = await Api.post('admin/musicas.php',formData);
        if (response.data.erro==='N') {
            const listagemAtualizada = listagem.map(musica => {
                //retorna o registro com o campo atualizado.
                if (musica.MUS_CODIGO === codigo) {
                    return {...musica, MUS_STATUS: e.value===true?'A':'I'}
                }    
                //returna o registro original.
                return musica;
            });
            setListagem(listagemAtualizada);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    // function exportar() {
    //     var formData = new FormData();
    //     formData.set('op', 'exportar');
    //     Api.post('admin/musicas.php',formData).then((response) => {
    //         console.log(response.data);
    //         // if (response.data.erro==='N') {
    //         //     setMusica(response.data.msg);
    //         // } else {
    //         //     setOperacao('listar');
    //         //     response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
    //         // }
    //     }).catch(({response}) => {
    //         console.log('CATCH Editar Categoria');
    //     });        
    // }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
      
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = e => {
        e.preventDefault()
      
        // Headers for each column
        let headers = ['CODIGO;STATUS;NOME'];
      
        // Convert users data to a csv
        let musicasCSV = listagem.reduce((acc, mus) => {
          const { MUS_CODIGO, MUS_STATUS, MUS_NOME } = mus;
          acc.push([MUS_CODIGO, MUS_STATUS, MUS_NOME].join(';'))
          return acc;
        }, []);

        downloadFile({
          data: [...headers, ...musicasCSV].join('\n'),
          fileName: 'musicas.csv',
          fileType: 'text/csv',
        });
    }

    return <>
        <BarraSuperior />
        <ColunaCentral left="false" right="false">
            <Cabecalho titulo="Cadastro de Músicas" texto="&nbsp;"/>

            <IfComponent condicional={operacao==="inserir" || operacao==="atualizar"}>
                <div className="row mb-3 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MUS_NOME" value={musica.MUS_NOME || ''} onChange={(e) => setCampo(e,'musica',false)} />
                            <label htmlFor="MUS_NOME">NOME</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MUS_LINK" value={musica.MUS_LINK || ''} onChange={(e) => setCampo(e,'musica',false)} />
                            <label htmlFor="MUS_LINK">LINK</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="MUS_TEMPO" value={musica.MUS_TEMPO || ''} onChange={(e) => setCampo(e,'musica',false)} mask="99:99"/>
                            <label htmlFor="MUS_TEMPO">TEMPO</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MUS_STATUS" value={musica.MUS_STATUS || ''} options={SelectItemsSTATUS} onChange={(e) => setCampo(e,'musica',false)} placeholder="Selecione"/>
                            <label htmlFor="MUS_STATUS">STATUS</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MUS_TIPO" value={musica.MUS_TIPO || ''} options={SelectItemsTIPOMUSICA} onChange={(e) => setCampo(e,'musica',false)} placeholder="Selecione"/>
                            <label htmlFor="MUS_TIPO">TIPO</label>
                        </span>
                    </div>
                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar()}><i className="fa fas fa-save"></i> Salvar</button>
                    <button className="btn btn-dark" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <div className="row mb-2 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="campo" value={pesquisa.campo || ''} options={SelectItemsTIPO} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                            <label htmlFor="campo">TIPO DE PESQUISA</label>
                        </span>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="60" name="texto" value={pesquisa.texto || ''} onChange={(e) => setCampo(e,'pesquisa',false)} />
                            <label htmlFor="texto">PESQUISA</label>
                        </span>
                    </div>
                </div>

                <div className="box shadow-sm d-flex justify-content-between">
                    <button className="btn btn-primary me-2" type="button" name="pesquisar" onClick={ () => pesquisar() }><i className="fa fas fa-search"></i> Pesquisar</button>
                    <button className="btn btn-outline-primary me-2" type="button" name="reset" onClick={ () => reset() }><i className="fa fas fa-refresh"></i> Limpar</button>
                    <button className="btn btn-outline-success ms-auto me-2" type="button" name="exportar" onClick={ (e) => exportToCsv(e) }><i className="fa far fa-file-excel"></i> Exportar</button>
                    <button className="btn btn-success" type="button" name="novo" onClick={ () => novo() }><i className="fa fas fa-plus"></i> Nova</button>
                </div>

                <IfComponent condicional={ listagem.length > 0}>
                    <div className="container mt-4 p-0"></div>
                    <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Código</th>
                                <th>Nome</th>
                                <th style={{ textAlign: 'center'}}>Tempo</th>
                                <th>Tipo</th>
                                <th style={{ textAlign: 'center'}}>Status</th>
                                <th>Link</th>
                                <th style={{ textAlign: 'right'}}>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {                     
                                listagem.map((musica,idx) => (
                                    <tr key={musica.MUS_CODIGO}>
                                        <td>{idx + 1}</td>
                                        <td>{musica.MUS_CODIGO}</td>
                                        <td>{musica.MUS_NOME}</td>
                                        <td align="center">{musica.MUS_TEMPO+' ('+musica.MUS_TEMPOSEG+' segs)'}</td>
                                        <td>{musica.MUS_TIPO==="N"?'NACIONAL':'INTERNACIONAL'}</td>
                                        <td align="center"><InputSwitch checked={musica.MUS_STATUS==="A"} onChange={(e) => setStatus(e,musica.MUS_CODIGO)} /></td>
                                        <td><a href={musica.MUS_LINK} target="_blank" rel="noreferrer">{musica.MUS_LINK}</a></td>
                                        <td align="right">
                                            <Link to="" onClick={() => editar(musica.MUS_CODIGO)} title="Editar"><i className="fa fa-fw fa-pencil"></i></Link>
                                            <Link to="" onClick={() => excluir({ 'codigo': musica.MUS_CODIGO, 'nome': musica.MUS_NOME })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                        </td>
                                    </tr>
                                ))
                            }   
                        </tbody>
                    </table>
                </IfComponent>
            </IfComponent>

            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-music"></i></span><br/>Nenhuma música encontrada!</p>
                </div>                    
            </IfComponent>
            <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>

        </ColunaCentral>
        <Rodape />
    </>
}

export default Musicas;