import React from 'react';

function Cabecalho(props) {
    const { titulo, texto } = props;
    return <>
        <table className="table">
            <tbody>
                <tr>
                    <td className="cabecalho" align="left">{titulo}</td>
                </tr>
            </tbody>
        </table>
        { texto!==""? <p>{texto}</p> : "" }
    </>
}

export default Cabecalho;