import React from 'react';

function NoticiaMaisLidos(props) {
    const { maisLidos } = props;
    return <>
        <div className="box">
            <blockquote className="titulo mb-3">Mais Lidos</blockquote>
            <ol className="list-group list-group-flush list-group-numbered">
                {
                    maisLidos.map((noticia,idx) => (
                        <li className="list-group-item text-truncate bg-transparent" style={{ color: '#efefef'}} key={idx}>
                            <a href={'/news/'+noticia.NOT_SLUG}>{noticia.NOT_TITULO}</a>
                        </li>
                    ))
                }
            </ol>
        </div>
    </>
}

export default NoticiaMaisLidos;