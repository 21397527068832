import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import Rodape from '../../componentes/Rodape';
import IfComponent from '../../componentes/IfComponent';

function Mensagens() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ operacao, setOperacao ] = useState('listar');
    const [ mensagem, setMensagem ] = useState({MSG_NOME: ''});
    const [ pesquisa, setPesquisa ] = useState({
        campo: 'CID_NOME',
        texto: ''
    });
    const SelectItemsTIPO = [
        {value: "MEN_NOME", label: "Nome" },
        {value: "MEN_EMAIL", label: "Email" }
    ];

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('campo', pesquisa.campo);
            formData.set('pesquisa', pesquisa.texto);
            Api.post('admin/mensagens.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar) {
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listar(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function pesquisar() {
        setPagina(0);
        setFinal(false);
        listar(0,false,true);
    };

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/mensagens.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        // Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listar(0,false,true);
                        setOperacao('listar');
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    function visualizar(codigo) {
        setOperacao('visualizar');
        var formData = new FormData();
        formData.set('op','visualizar');
        formData.set('codigo',codigo);
        Api.post('admin/mensagens.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setMensagem(response.data.msg);
            } else {
                setOperacao('listar');
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH visualizar');
        });
    }

    function cancelar() {
        setOperacao('listar');
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        // if (objeto==="cidade") {
        //     setCidade({...cidade, [name]: valor});
        // }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left={false} right={false}>
            <Cabecalho titulo="Mensagens recebidas pelo formulário de contato" texto=""/>

            <IfComponent condicional={operacao==="visualizar"}>
                <div className="row mt-5 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MSG_CODIGO" value={mensagem.MSG_CODIGO || ''} readOnly/>
                            <label htmlFor="MSG_CODIGO">CÓDIGO</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MSG_DATA" value={mensagem.MSG_DATA || ''} readOnly/>
                            <label htmlFor="MSG_DATA">DATA</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MSG_HORA" value={mensagem.MSG_HORA || ''} readOnly/>
                            <label htmlFor="MSG_HORA">HORA</label>
                        </span>
                    </div>
                </div>
                <div className="row gx-1 p-inputtext-sm">
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MSG_NOME" value={mensagem.MSG_NOME || ''} readOnly/>
                            <label htmlFor="MSG_NOME">NOME</label>
                        </span>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MSG_EMAIL" value={mensagem.MSG_EMAIL || ''} readOnly/>
                            <label htmlFor="MSG_EMAIL">EMAIL</label>
                        </span>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="MSG_ASSUNTO" value={mensagem.MSG_ASSUNTO || ''} readOnly/>
                            <label htmlFor="MSG_ASSUNTO">ASSUNTO</label>
                        </span>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <section className="text-light" dangerouslySetInnerHTML={{ __html: mensagem.MSG_MENSAGEM }} />
                    </div>
                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    {/* <button className="btn btn-success me-2" onClick={() => salvar()}><i className="fa fas fa-save"></i> Salvar</button> */}
                    <button className="btn btn-dark me-2" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                    <button className="btn btn-danger" onClick={() => excluir({ 'codigo': mensagem.MSG_CODIGO, 'nome': mensagem.MSG_NOME })} title="Excluir"><i className="fa fa-fw fa-trash"></i> Excluir</button>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <div className="row mt-5 mb-2 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="campo" value={pesquisa.campo || ''} options={SelectItemsTIPO} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                            <label htmlFor="campo">TIPO DE PESQUISA</label>
                        </span>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="texto" value={pesquisa.texto || ''} onChange={(e) => setCampo(e,'pesquisa',false)} />
                            <label htmlFor="texto">PESQUISA</label>
                        </span>
                    </div>
                </div>

                <div className="box shadow-sm d-flex justify-content-between">
                    <input type="hidden" value="pesquisar" name="op" />
                    <button className="btn btn-primary me-2" type="button" name="pesquisar" onClick={ () => pesquisar() }><i className="fa fas fa-search"></i> Pesquisar</button>
                </div>

                <IfComponent condicional={ listagem.length > 0}>
                    <div className="container mt-4 p-0"></div>
                    <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Data/Hora</th>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Assunto</th>
                                <th style={{ textAlign: 'right'}}>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {                     
                                listagem.map(msg => (
                                    <tr key={msg.MSG_CODIGO}>
                                        <td>{msg.MSG_CODIGO}</td>
                                        <td>{msg.MSG_DATA+' - '+msg.MSG_HORA}</td>
                                        <td>{msg.MSG_NOME}</td>
                                        <td>{msg.MSG_EMAIL}</td>
                                        <td>{msg.MSG_ASSUNTO}</td>
                                        <td align="right">
                                            <Link to="" onClick={() => visualizar(msg.MSG_CODIGO)} title="Visualizar"><i className="fa fa-fw fa-eye"></i></Link>
                                            <Link to="" onClick={() => excluir({ 'codigo': msg.MSG_CODIGO, 'nome': msg.MSG_NOME })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                        </td>
                                    </tr>
                                ))
                            }   
                        </tbody>
                    </table>
                </IfComponent>
            </IfComponent>

            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-comments"></i></span><br/>Nenhuma mensagem encontrada!</p>
                </div>                    
            </IfComponent>
            <div className="container limit-width bg-transparent pb-5" ref={scrollObserver} id="sentinela"></div>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default Mensagens;