import React, { useEffect, useState } from 'react';
import axios from 'axios';
import IfComponent from './IfComponent';

function InstaFeed() {
    const [ feedList, setFeedList ] = useState([]);

    async function getInstaFeed() {
        const token = process.env.REACT_APP_INSTA;
        const fields = 'media_url,media_type,permalink'
        const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=${fields}`
        const response = await axios.get(url);
        setFeedList(response.data.data);
    }

    useEffect(() => {
        getInstaFeed();
    },[]);

    return <>
        {/* <div className="insta_container border">
            { 
                feedList.map(item => (
                    <a className="insta_item" href={item.permalink} target="_blank" rel="noreferrer" key={item.id} >
                        <IfComponent condicional={(item.media_type==="IMAGE") || (item.media_type==="CAROUSEL_ALBUM")}>
                            <img className="rounded" src={item.media_url} alt="img"/>
                        </IfComponent>
                        <IfComponent condicional={item.media_type==="VIDEO"}>
                            <video className="rounded" controls>
                                <source src={item.media_url}/>
                            </video>
                        </IfComponent>
                    </a>
                ))
            }
        </div> */}

        <div className="container">
            <div className="row row-cols-1 g-3 d-flex justify-content-center">
                { 
                    feedList.map(item => (
                        <div className="col" key={item.id} style={{maxHeight: '175px', maxWidth: '175px'}}>
                            <a href={item.permalink} target="_blank" rel="noreferrer">
                                {/* <div className="card h-100 overflow-hidden" style={{maxHeight: '120px', maxWidth: '120px', objectFit: 'cover'}}>  */}
                                    <IfComponent condicional={(item.media_type==="IMAGE") || (item.media_type==="CAROUSEL_ALBUM")}>    
                                        <div className="card h-100 border-0 overflow-hidden" style={{background: `url(${item.media_url})`, backgroundSize: 'cover'}}>
                                            <svg><rect width="100%" height="100%" fill="transparent"></rect></svg>
                                        </div>
                                    </IfComponent>
                                    <IfComponent condicional={item.media_type==="VIDEO"}>
                                        <div className="card h-100 overflow-hidden border-0">
                                            <video className="card-img overflow-hidden" style={{maxHeight: '160px', maxWidth: '160px', objectFit: 'cover'}}>
                                                <source src={item.media_url}/>
                                            </video>
                                        </div>
                                    </IfComponent>
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>

        {/* <div className="container border">
             <div className="row row-cols-4 g-2">
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <div className="card h-100 overflow-hidden">
                        <img src="https://scontent.cdninstagram.com/v/t51.29350-15/284293849_168951548929786_531483616199334626_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=uqRM7dAev-gAX_m8CUm&_nc_oc=AQlfgE0RZUOTiQ0tyHa6or-xQAJGhsqQfHlnR6jzAVK0splkVlr34DlnYWVX6NevLG8&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDoequiOh4A4hHuBkAniLHP7dGXjOHhwaHo4P8bexRNtA&oe=641DE724" className="card-img overflow-hidden" alt="..."  style={{objectFit: 'cover'}} />
                    </div>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <div className="card h-100 overflow-hidden">
                        <img src="https://scontent.cdninstagram.com/v/t51.29350-15/283638517_1314355905754127_7392177566490613096_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=0h5927wn-nYAX9-NLTH&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDck36lm7NVC3BT0lfOLEIEDebF4KiAgJpYUiA_jFtf7w&oe=641D709D" className="card-img overflow-hidden rounded" alt="..."/>
                    </div>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <div className="card h-100 overflow-hidden">
                        <img src="https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD" className="card-img" alt="..." />
                    </div>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA">
                        <div className="card h-100 overflow-hidden" >
                        <video className="card-img overflow-hidden rounded" >
                            <source src="https://video.cdninstagram.com/o1/v/t16/f1/m82/C64371807F87C71AF2D4AE1820A984A4_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjQ4MC5jbGlwcyJ9&_nc_ht=video.cdninstagram.com&_nc_cat=107&vs=673475997390748_2057448656&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9DNjQzNzE4MDdGODdDNzFBRjJENEFFMTgyMEE5ODRBNF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR095b3BSS1FhTXYwLVZrQ0FGbDhuZDBJNHJJWmJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAmiI7d7P%2F%2BwUAVAigCQzMsF0A3nS8an753GBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA%3D%3D&ccb=9-4&oh=00_AfAR1ZnKyp0A1rFfhX2d4uFd9U-FgNrtTJ3BdBHcS3QrvA&oe=641A05CC&_nc_sid=ea0b6e&_nc_rid=33b75df36e"/>
                        </video>
                    </div>
                    </a>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <div className="card h-100 overflow-hidden" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/335514213_773906630493139_3249447563739447105_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Ibjiq-ul_sMAX_gtpDl&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDvxpIjwtOeJGgbTWW3BuiC0Ji1OmZ9HRnaH85ueoZ6Qg&oe=641DCEE0)', backgroundSize: 'cover'}} >
                    </div>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA"><div className="card h-100" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD)', backgroundSize: 'cover'}} >
                        <img src="" className="card-img" alt="..." />
                    </div>
                    </a>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA"><div className="card h-100" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD)', backgroundSize: 'cover'}} >
                        <img src="" className="card-img" alt="..." />
                    </div>
                    </a>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA"><div className="card h-100" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD)', backgroundSize: 'cover'}} >
                        <img src="" className="card-img" alt="..." />
                    </div>
                    </a>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA"><div className="card h-100" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD)', backgroundSize: 'cover'}} >
                        <img src="" className="card-img" alt="..." />
                    </div>
                    </a>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA"><div className="card h-100" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD)', backgroundSize: 'cover'}} >
                        <svg><rect width="100%" height="100%" fill="transparent"></rect></svg>
                    </div>
                    </a>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA"><div className="card h-100" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD)', backgroundSize: 'cover'}} >
                        <img src="" className="card-img" alt="..." />
                    </div>
                    </a>
                </div>
                <div className="col" style={{maxHeight: '160px', maxWidth: '160px'}}>
                    <a href="aaA"><div className="card h-100" style={{background: 'url(https://scontent.cdninstagram.com/v/t51.29350-15/292304743_1330162537391451_7822076337675069302_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=KKjpR5Wvi20AX-AP36_&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD46EmsaFnkKklln_XlWWQuDbep_oTtHdVtFRgxywzkKA&oe=641CE5FD)', backgroundSize: 'cover'}} >
                        <img src="" className="card-img" alt="..." />
                    </div>
                    </a>
                </div>
            </div>
        </div>  */}
    </>
}

export default InstaFeed;