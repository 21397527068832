import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import Swal from 'sweetalert2';

import Api from '../Api';
// import Banners from '../componentes/Banners';
import BarraSuperior from '../componentes/BarraSuperior';
import ColunaCentral from '../componentes/ColunaCentral';
import Rodape from '../componentes/Rodape';
import Bloco from '../componentes/Bloco';
import InstaFeed from '../componentes/InstaFeed';

function Home() {
    const [ zaps, setZaps ] = useState({
        'nome': '',
        'fone': '',
    });
    const [ enviando, setEnviando ] = useState(false);

    // async function getBanners(unidade,grupo) {
    //     const formData = new FormData();
    //     formData.append('op','listar');
    //     formData.append('unidade',unidade);
    //     formData.append('grupo',grupo);
    //     const response = await Api.post('banners.php',formData);
    //     if (response.data.erro==='N') {
    //         setBanners(response.data.msg);
    //     } else {
    //         console.log('Erro ao consultar os banners');
    //     }
    // }

    // useEffect(() => {
    //     getBanners(1,10);
    // },[])
    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="zaps") {
            setZaps({...zaps, [name]: valor});
        }
    }

    async function salvar() {
        setEnviando(true);
        const formData = new FormData();
        formData.set('op','enviar');
        formData.set('nome',zaps.nome);
        formData.set('fone',zaps.fone);
        const response = await Api.post('zaps.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            setZaps({        
                nome: '',
                fone: ''
            });
        } 
        if (response.data.erro==='S') {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
        setEnviando(false);
    }

    return <>
        <BarraSuperior />
        {/* <div className='mt-5'></div> */}
        {/* <Banners banners={banners}/> */}
        <ColunaCentral>
            <Bloco id={1} pagina="S" titulo={false} texto=""/>
            <hr />
            <div className="container" style={{maxWidth: '450px'}}>
                <p className="lead text-center">Receba a atualização da agenda da banda.</p>
                <div className="row pett-input">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="nome" value={zaps.nome || ''} onChange={(e) => setCampo(e,'zaps',false)} />
                            <label htmlFor="nome">NOME</label>
                        </span>
                    </div>
                </div>
                <div className="row pett-input">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="fone" value={zaps.fone || ''} onChange={(e) => setCampo(e,'zaps',false)} mask="99 99999-9999"/>
                            <label htmlFor="fone">WHATSAPP</label>
                        </span>
                    </div>
                    <div className="mt-3 mb-4">
                        {
                            enviando===true
                                ? <button className="btn btn-success me-2 w-100" disabled><i className="pi pi-fw pi-spin pi-spinner"></i> Enviando...</button>
                                : <button className="btn btn-success me-2 w-100" onClick={() => salvar()}><i className="fa fas fa-paper-plane"></i> Enviar</button>
                        }                      
                    </div>
                </div>
            </div>
            <hr />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className="lead text-center">Siga-nos no Insta</p>
                        <p className="text-center text-white">
                            <a className="text-white fw-bold fs-4" href="https://instagram.com/crazymariarock/" rel="noreferrer" target="_blank"><i className="fa fa-fw fa-instagram"></i> crazymariarock</a>
                        </p>
                    </div>
                </div>
            </div>
            <InstaFeed/>
            <hr />

        </ColunaCentral>
        <Rodape/>
        {/* <img className="img-responsive" src="/imagens/logo_runtime_black.svg" alt="Runtime Sistemas" style={{maxHeight: '50px'}}/> */}
        {/* <header className="fixed-top Fixed">  
            {/* <nav className="navbar navbar-expand-lg navbar-light d-none d-lg-block d-xl-block bg-color1" style={{height: '24px', maxHeight: '24px'}} id="barramenu1">
                <div className="container">
                    <div className="navbar-collapse collapse" id="barramenu1">
                        <div className="menubarra">
                            <a href="/contato.php">Contato</a>
                            <a href="https://wa.me/5547988154783" target="_blank"><i className="fab fa-fw fa-whatsapp"></i> WhatsApp</a>
                            <a href="https://www.facebook.com/dentalplusonline/" target="_blank"><i className="fab fa-fw fa-facebook"></i> Facebook</a>
                            <a href="https://instagram.com/dentalplusonline/" target="_blank"><i className="fab fa-fw fa-instagram"></i> Instagram</a>
                            <a href="/carrinho.php"><i className="fa fa-fw fa-shopping-cart"></i> Carrinho</a>
                        </div>
                    </div>
                </div>
            </nav> */}
            
            {/* <nav className="navbar navbar-expand-lg navbar-dark bg-color2" id="barramenu2">
                <div className="container border">
                    <a href="/index.php" className="navbar-brand"><img className="img-responsive" src="https://runtimesistemas.com.br/userfiles/images/logo_runtime_black.svg" style={{maxHeight: '70px'}} alt="Runtime Sistemas" /></a>
                    <a className="navbar-toggler" href="#menumobile" style={{color: '#fff'}}><span className="navbar-toggler-icon"></span></a>
                    <MenuDrop />
                </div>
            </nav>
        </header>  */}
    </>
}

export default Home;