import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import ReCAPTCHA from "react-google-recaptcha";

// funcoes proprias.
import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';
import ColunaCentral from '../componentes/ColunaCentral';
import Rodape from '../componentes/Rodape';
import Cabecalho from '../componentes/Cabecalho';
import Bloco from '../componentes/Bloco';

function Contato() {
    const [ token, setToken ] = useState(false);
    const [ destinatarios, setDestinatarios ] = useState([]);
    const [ contato, setContato ] = useState({
        nome: '',
        email: '',
        assunto: '',
        destinatario: '1',
        mensagem: ''
    });

    const getDestinatarios = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('contato.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setDestinatarios(response.data.msg);
            }
        });
    },[]);

    useEffect(() => {
        getDestinatarios();
    },[getDestinatarios]);

    function EnviarMensagem() {
        var formData = new FormData();
        formData.set('op', 'enviar');
        formData.set('nome', contato.nome);
        formData.set('email', contato.email);
        formData.set('assunto', contato.assunto);
        formData.set('destinatario', contato.destinatario);
        formData.set('mensagem', contato.mensagem);

        Api.post('contato.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setContato({nome: '', email: '', assunto: '', destinatario: '1', mensagem: ''});
                Swal.fire({title: 'Ebaaaaaa!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
            }
        }).catch(({response}) => {
            console.log('CATCH: '+JSON.stringify(response));
        });  
    }
  
    const setCampo = (e) => {
        const { name, value } = e.target;
        //let valor = value?value.toUpperCase():'';
        let valor = value;
        if (name==='email') {
            valor = valor.toLowerCase();
        }
        setContato({...contato, [name]: valor});
    }

    function verificarCaptcha(e) {
        setToken(true);
    }

    useEffect(() => {
        setToken(false);
    },[]);

    return <>
        <BarraSuperior />
        <ColunaCentral left="false" right="false">
            <Cabecalho titulo="Contato"/>
            <Bloco id="74" pagina="N"/>
            {/* <div className="container-fluid limit-width py-5"> */}
                <div className="row mt-3 mb-3 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-6 col-md-6 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" id="nome" name="nome" value={contato.nome} onChange={(e) => setCampo(e)} required/>
                            <label htmlFor="nome">NOME</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" id="email" name="email" value={contato.email} onChange={(e) => setCampo(e)} required/>
                            <label htmlFor="email">EMAIL</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" id="assunto" name="assunto" value={contato.assunto} onChange={(e) => setCampo(e)} required/>
                            <label htmlFor="assunto">ASSUNTO</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" id="destinatario" name="destinatario" options={destinatarios} value={String(contato.destinatario)} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                            <label className="control-label" htmlFor="destinatario">DESTINATÁRIO</label>
                        </span>
                    </div>
                    <div className="col-md-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputTextarea className="w-100" id="mensagem" name="mensagem" value={contato.mensagem} onChange={(e) => setCampo(e)} rows={10}/>
                            <label htmlFor="mensagem">MENSAGEM</label>
                        </span>
                    </div>
                    <div className="col-md-12 mb-1">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA} theme='dark' onChange={(e) => verificarCaptcha(e)}/>
                    </div>
                    <div className="mt-3 w-100 box shadow">
                        {/* <GoogleReCaptchaProvider reCaptchaKey="xxxxxxxxxxxxx"> */}
                            <button className={token?"btn btn-success":"btn btn-success disabled"} type="button" onClick={()=>EnviarMensagem()}>ENVIAR</button>
                        {/* </GoogleReCaptchaProvider> */}
                    </div>
                </div>
            {/* </div> */}
        </ColunaCentral>
        <Rodape/>
    </>
}

export default Contato;