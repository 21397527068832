import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import Api from '../Api';
import BarraSuperior from '../componentes/BarraSuperior';
import ColunaCentral from '../componentes/ColunaCentral';
// import IfComponent from '../componentes/IfComponent';
import NoticiaCorpo from '../componentes/NoticiaCorpo';
import NoticiaArquivos from '../componentes/NoticiaArquivos';
import NoticiaCategorias from '../componentes/NoticiaCategorias';
import NoticiaMaisLidos from '../componentes/NoticiaMaisLidos';
import NoticiaTags from '../componentes/NoticiaTags';
// import NoticiaCard from '../componentes/NoticiaCard';
import Rodape from '../componentes/Rodape';
import IfComponent from '../componentes/IfComponent';

function News() {
    const { slug } = useParams();
    const [ noticia, setNoticia ] = useState({NOT_CODIGO:0});
    const [ noticiaTags, setNoticiaTags ] = useState([]);
    const [ categorias, setCategorias ] = useState([]);
    const [ maisLidos, setMaisLidos ] = useState([]);
    const [ tags, setTags ] = useState([]);
    const [ anoAtual, setAnoAtual ] = useState([]);
    const [ anosRestantes, setAnosRestantes ] = useState([]);

    // function verNoticia() {
    //     var formData = new FormData();
    //     formData.set('op', 'ver');
    //     formData.set('codigo', slug);
        
    //     Api.post('news.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setNoticia(response.data.msg);
    //             console.log('zzzzzz',response.data.msg)
    //             setNoticiaTags(response.data.msg.NOT_TAGS);
    //         } else {
    //             Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
    //         }
    //     }).catch(({response}) => {
    //         Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
    //     });            
    // }

    const verNoticia = useCallback(() => {
        const formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', slug);
        Api.post('news.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setNoticia(response.data.msg);
                setNoticiaTags(response.data.msg.NOT_TAGS);
            } else {
                setNoticia({NOT_CODIGO:0});
                //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });
    },[slug]);

    const getCategorias = useCallback(() => {
        const formData = new FormData();
        formData.set('op','listar');
        Api.post('/categorias.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setCategorias(response.data.msg);
            }
        });
    },[]);

    const getMaisLidos = useCallback(() => {
        const formData = new FormData();
        formData.set('op','maislidos');
        Api.post('/news.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setMaisLidos(response.data.msg);
            }
        });
    },[]);

    const getTags = useCallback(() => {
        const formData = new FormData();
        formData.set('op','tags');
        Api.post('/news.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setTags(response.data.msg);
            }
        });
    },[]);

    const getArquivos = useCallback(() => {
        const formData = new FormData();
        formData.set('op','arquivos');
        Api.post('/news.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setAnoAtual(response.data.msg.atual);
                setAnosRestantes(response.data.msg.restante);
            }
        });
    },[]);

    useEffect(() => {      
        getCategorias();
        getMaisLidos();
        getTags();
        getArquivos();
        verNoticia();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false">

            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 hidden-xs">
                    <NoticiaCategorias categorias={categorias}/>
                    <NoticiaMaisLidos maisLidos={maisLidos}/>
                    <NoticiaTags tags={tags}/>
                    <NoticiaArquivos anoAtual={anoAtual} anosRestantes={anosRestantes} />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <IfComponent condicional={noticia.NOT_CODIGO!==0}>
                        <NoticiaCorpo noticia={noticia} tags={noticiaTags} visualizar={true}/>
                    </IfComponent>
                    <IfComponent condicional={noticia.NOT_CODIGO===0}>
                        <div className="text-center fs-4 mt-5">Notícia não localizada.</div>
                    </IfComponent>
                </div>
            </div>
        

        </ColunaCentral>
        <Rodape/>
    </>
}

export default News;