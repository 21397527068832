import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import Api from "../Api";
import { AuthContext } from '../contextos/Auth';

function ListagemArquivos(props) {
    const { logout } = useContext(AuthContext);
    const [ listagem, setListagem ] = useState([]);
    const { arquivos, endpoint } = props;
    
    useEffect(() => {
        setListagem(arquivos);
    },[arquivos]);

    function excluir(arq) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+arq.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluirarquivo');
                formData.set('codigo',arq.codigo);
                Api.post('admin/'+endpoint,formData).then((response) => {
                    if (response.data.erro==='N') {
                        
                        //remove o item da listagem.
                        let novaListagem = [];
                        novaListagem = listagem.filter(item => (String(item.codigo) !== String(arq.codigo)));
                        setListagem(novaListagem);

                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('CATCH: '+JSON.stringify(response));
                });
            }
        });
    }

    return <>
        <h6 className="text-light mt-4 fw-bold">Arquivos disponíveis</h6>
        <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Clique para copiar</th>
                    <th style={{ textAlign: 'right'}}>Opções</th>
                </tr>
            </thead>
            <tbody>
            {
                listagem.map(arquivo => (
                    <tr key={arquivo.codigo}>
                        <td>{arquivo.contador}</td>
                        <td>{arquivo.nome}</td>
                        <td><span className="btn badge bg-info text-black" style={{cursor: 'pointer'}} onClick={() => {navigator.clipboard.writeText(arquivo.pasta+arquivo.nome)}}>Copiar</span></td>
                        <td align="right">
                            <Link to="" onClick={() => excluir({ 'codigo': arquivo.codigo, 'nome': arquivo.nome })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                        </td>
                    </tr>
                ))
            }
            </tbody>            
        </table>
    </>
}

export default ListagemArquivos;