import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';

import Api from '../../Api';
import { AuthContext } from '../../contextos/Auth';
import BarraSuperior from '../../componentes/BarraSuperior';
import Cabecalho from '../../componentes/Cabecalho';
import ColunaCentral from '../../componentes/ColunaCentral';
import Rodape from '../../componentes/Rodape';
import IfComponent from '../../componentes/IfComponent';

function Usuarios() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ pesquisa, setPesquisa ] = useState({
        campo: 'USU_NOME',
        texto: ''
    });
    const [ usuario, setUsuario ] = useState({USU_NOME: ''});
    const [ operacao, setOperacao ] = useState('listar');
    const SelectItemsTIPO = [
        {value: "USU_NOME", label: "Nome" },
        {value: "USU_EMAIL", label: "Email" },
        {value: "USU_CODIGO", label: "Código" },
        {value: "USU_DTCADASTRO", label: "Período de Cadastro" }
    ];

    const SelectItemsPESSOA = [
        {value: "F", label: "Física" },
        {value: "J", label: "Jurídica" }
    ];  

    const SelectItemsSIMNAO = [
        {value: "S", label: "SIM" },
        {value: "N", label: "NÃO" }
    ];  

    const SelectItemsSTATUS = [
        {value: "A", label: "ATIVO" },
        {value: "I", label: "INATIVO" }
    ]; 

    const [ cidades, setCidades ] = useState([]);
    const [ estados, setEstados ] = useState([]);

    const getEstados = useCallback(() => {
        const formData = new FormData();
        formData.set('op','comboestados');
        Api.post('enderecos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setEstados(response.data.msg);
            }
        });
    },[]);

    const getCidades = useCallback((uf) => {
        const formData = new FormData();
        formData.set('op','combocidades');
        formData.set('estado',uf);
        Api.post('enderecos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setCidades(response.data.msg);
            }
        });
    },[]); 

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
                formData.set('op', 'listar');
                formData.set('pagina', novaPagina);
                formData.set('campo', pesquisa.campo);
                formData.set('pesquisa', pesquisa.texto);
            Api.post('admin/usuarios.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar) {
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listar(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function pesquisar() {
        setPagina(0);
        setFinal(false);
        listar(0,false,true);
    };

    function reset() {
        window.location.reload();
        // const pesquisa = { campo: 'CON_NOME', texto: '' };
        // //setPesquisa({...pesquisa, texto: ''});
        // setPesquisa(pesquisa);
        // setPagina(0);
        // setFinal(false);
        // listar(0,false,true);
    };

    function cancelar() {
        setOperacao('listar');
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="usuario") {
            setUsuario({...usuario, [name]: valor});
        }
        if (objeto==="pesquisa") {
            setPesquisa({...pesquisa, [name]: valor});
        }

        if (name==="USU_ESTADOCODIGO") {
            getCidades(valor);
        }
    }

    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.nome+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.codigo);
                Api.post('admin/usuarios.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        setPagina(0);
                        setFinal(false);
                        listar(0,false,true);
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('catch: '+JSON.stringify(response));
                });
            }
        });
    }

    function editar(codigo) {
        setOperacao('atualizar');
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', codigo);
        Api.post('admin/usuarios.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setUsuario(response.data.msg);
                getEstados();
                getCidades(response.data.msg.USU_ESTADOCODIGO);
            } else {
                setOperacao('listar');
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH Editar');
        });
    }

    async function salvar() {
        const formData = new FormData();
        formData.set('op',operacao);
        formData.set('USU_ADMIN',usuario.USU_ADMIN || '');
        formData.set('USU_APELIDO',usuario.USU_APELIDO || '');
        formData.set('USU_BAIRRO',usuario.USU_BAIRRO || '');
        formData.set('USU_CEP',usuario.USU_CEP || '');
        formData.set('USU_CIDADECODIGO',usuario.USU_CIDADECODIGO || '');
        formData.set('USU_COMPLEMENTO',usuario.USU_COMPLEMENTO || '');
        formData.set('USU_CNPJ',usuario.USU_CNPJ || '');
        formData.set('USU_CODIGO',usuario.USU_CODIGO);
        formData.set('USU_CPF',usuario.USU_CPF || '');
        formData.set('USU_EMAIL',usuario.USU_EMAIL || '');
        formData.set('USU_ENDERECO',usuario.USU_ENDERECO || '');
        formData.set('USU_ESTADOCODIGO',usuario.USU_ESTADOCODIGO || '');
        formData.set('USU_FONE1',usuario.USU_FONE1 || '');
        formData.set('USU_FONE2',usuario.USU_FONE2 || '');
        formData.set('USU_GESTOR',usuario.USU_GESTOR || '');
        formData.set('USU_NOME',usuario.USU_NOME || '');
        formData.set('USU_NUMERO',usuario.USU_NUMERO || '');
        formData.set('USU_RECEBENEWS',usuario.USU_RECEBENEWS || '');
        formData.set('USU_STATUS',usuario.USU_STATUS || '');
        formData.set('USU_TIPOPESSOA',usuario.USU_TIPOPESSOA || '');

        const response = await Api.post('admin/usuarios.php',formData);
        if (response.data.erro==='N') {
            let novaListagem = [];
            Swal.fire({title: 'Yessss!', text: 'Usuário atualizado com sucesso.', icon: 'success', showConfirmButton: false, timer: 1500});
            novaListagem = listagem.filter(item => (String(item.USU_CODIGO) !== String(usuario.USU_CODIGO)));
            novaListagem.unshift(response.data.msg); //adiciona no inicio da listagem.

            // ordena a listagem.
            const novaListagemSort = novaListagem.sort(function(c1,c2) {
                if (c1.USU_NOME > c2.USU_NOME) return  1;
                if (c1.USU_NOME < c2.USU_NOME) return -1;
                return 0;
            });

            setListagem(novaListagemSort);
            setOperacao('listar');
            setUsuario({        
                USU_CODIGO: 0,
                USU_NOME: '',
            });
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function setStatus(e,codigo) {
        const formData = new FormData();
        formData.set('op','status');
        formData.set('codigo',codigo);
        formData.set('status',e.value===true?'A':'I');
        const response = await Api.post('admin/usuarios.php',formData);
        if (response.data.erro==='N') {
            console.log(codigo, response.data.msg)
            //Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            const listagemAtualizada = listagem.map(usuario => {
                //retorna o registro com o campo atualizado.
                if (usuario.USU_CODIGO === codigo) {
                    return {...usuario, USU_STATUS: e.value===true?'A':'I'}
                }    
                //returna o registro original.
                return usuario;
            });
            setListagem(listagemAtualizada);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function setAdmin(e,codigo) {
        const formData = new FormData();
        formData.set('op','admin');
        formData.set('codigo',codigo);
        formData.set('admin',e.value===true?'S':'N');
        const response = await Api.post('admin/usuarios.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            const listagemAtualizada = listagem.map(usuario => {
                //retorna o registro com o campo atualizado.
                if (usuario.USU_CODIGO === codigo) {
                    return {...usuario, USU_ADMIN: e.value===true?'S':'N'}
                }    
                //returna o registro original.
                return usuario;
            });
            setListagem(listagemAtualizada);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function setGestor(e,codigo) {
        const formData = new FormData();
        formData.set('op','gestor');
        formData.set('codigo',codigo);
        formData.set('gestor',e.value===true?'S':'N');
        const response = await Api.post('admin/usuarios.php',formData);
        if (response.data.erro==='N') {
            //Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            const listagemAtualizada = listagem.map(usuario => {
                //retorna o registro com o campo atualizado.
                if (usuario.USU_CODIGO === codigo) {
                    return {...usuario, USU_GESTOR: e.value===true?'S':'N'}
                }    
                //returna o registro original.
                return usuario;
            });
            setListagem(listagemAtualizada);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    return <>
        <BarraSuperior />
        <ColunaCentral left="false" right="false">
            <Cabecalho titulo="Cadastro de Usuários" texto="&nbsp;"/>

            <IfComponent condicional={operacao==="inserir" || operacao==="atualizar"}>
                <div className="row mt-3 mb-3 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_CODIGO" value={usuario.USU_CODIGO || ''} disabled/>
                            <label htmlFor="USU_CODIGO">CÓDIGO</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_DTCADASTRO" value={usuario.USU_DTCADASTRO || ''} disabled/>
                            <label htmlFor="USU_DTCADASTRO">DATA CADASTRO</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_DTATUALIZACAO" value={usuario.USU_DTATUALIZACAO || ''} disabled/>
                            <label htmlFor="USU_DTATUALIZACAO">DATA ATUALIZAÇÃO</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_USUARIO" value={usuario.USU_ATUALIZADOPOR || ''} disabled/>
                            <label htmlFor="USU_USUARIO">ATUALIZADO POR</label>
                        </span>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_NOME" value={usuario.USU_NOME || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_NOME">NOME</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_APELIDO" value={usuario.USU_APELIDO || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_APELIDO">APELIDO</label>
                        </span>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_EMAIL" value={usuario.USU_EMAIL || ''} onChange={(e) => setCampo(e,'usuario',false)} />
                            <label htmlFor="USU_EMAIL">EMAIL</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="USU_TIPOPESSOA" options={SelectItemsPESSOA} value={usuario.USU_TIPOPESSOA || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_TIPOPESSOA">TIPO PESSOA</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="USU_CPF" value={usuario.USU_CPF || ''} onChange={(e) => setCampo(e,'usuario',false)} mask="999.999.999-99" />
                            <label htmlFor="USU_CPF">CPF</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="USU_CNPJ" value={usuario.USU_CNPJ || ''} onChange={(e) => setCampo(e,'usuario',false)} mask="99.999.999/9999-99" />
                            <label htmlFor="USU_CNPJ">CNPJ</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="USU_FONE1" value={usuario.USU_FONE1 || ''} onChange={(e) => setCampo(e,'usuario',false)} mask="99 99999-9999" />
                            <label htmlFor="USU_FONE1">FONE 01</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="USU_FONE2" value={usuario.USU_FONE2 || ''} onChange={(e) => setCampo(e,'usuario',false)} mask="99 99999-9999" />
                            <label htmlFor="USU_FONE2">FONE 02</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="USU_RECEBENEWS" options={SelectItemsSIMNAO} value={String(usuario.USU_RECEBENEWS) || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_RECEBENEWS">RECEBE NEWS</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputMask className="w-100" name="USU_CEP" value={usuario.USU_CEP || ''} onChange={(e) => setCampo(e,'usuario',false)} mask="99999-999" />
                            <label htmlFor="USU_CEP">CEP</label>
                        </span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_ENDERECO" value={usuario.USU_ENDERECO || ''} onChange={(e) => setCampo(e,'usuario',false)} />
                            <label htmlFor="USU_ENDERECO">ENDEREÇO</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_NUMERO" value={usuario.USU_NUMERO || ''} onChange={(e) => setCampo(e,'usuario',false)} />
                            <label htmlFor="USU_NUMERO">NÚMERO</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_COMPLEMENTO" value={usuario.USU_COMPLEMENTO || ''} onChange={(e) => setCampo(e,'usuario',false)} />
                            <label htmlFor="USU_COMPLEMENTO">COMPLEMENTO</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" name="USU_BAIRRO" value={usuario.USU_BAIRRO || ''} onChange={(e) => setCampo(e,'usuario',false)} />
                            <label htmlFor="USU_BAIRRO">BAIRRO</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="USU_ESTADOCODIGO" options={estados} value={String(usuario.USU_ESTADOCODIGO) || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_ESTADOCODIGO">ESTADO</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="USU_CIDADECODIGO" options={cidades} value={String(usuario.USU_CIDADECODIGO) || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_CIDADECODIGO">CIDADE</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="USU_STATUS" options={SelectItemsSTATUS} value={usuario.USU_STATUS} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_STATUS">STATUS</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="USU_ADMIN" options={SelectItemsSIMNAO} value={usuario.USU_ADMIN || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_ADMIN">ADMIN</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="USU_GESTOR" options={SelectItemsSIMNAO} value={usuario.USU_GESTOR || ''} onChange={(e) => setCampo(e,'usuario',false)}/>
                            <label htmlFor="USU_GESTOR">GESTOR</label>
                        </span>
                    </div>
                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar()}><i className="fa fas fa-save"></i> Salvar</button>
                    <button className="btn btn-dark" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <div className="row mb-2 gx-1 p-inputtext-sm pett-input">
                    <div className="col-lg-2 col-md-2 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="campo" value={pesquisa.campo || ''} options={SelectItemsTIPO} onChange={(e) => setCampo(e,'pesquisa',false)} placeholder="Selecione"/>
                            <label htmlFor="campo">TIPO DE PESQUISA</label>
                        </span>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-12 mb-1">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="texto" value={pesquisa.texto || ''} onChange={(e) => setCampo(e,'pesquisa',false)} />
                            <label htmlFor="texto">PESQUISA</label>
                        </span>
                    </div>
                </div>

                <div className="box shadow-sm">
                    <input type="hidden" value="pesquisar" name="op" />
                    <button className="btn btn-primary me-2" type="button" name="pesquisar" onClick={ () => pesquisar() }><i className="fa fas fa-search"></i> Pesquisar</button>
                    <button className="btn btn-outline-primary me-2" type="button" name="reset" onClick={ () => reset() }><i className="fa fas fa-refresh"></i> Limpar</button>
                </div>

                <IfComponent condicional={ listagem.length > 0}>
                    <div className="container mt-4 p-0"></div>
                    <table className="table table-condensed table-bordered table-striped table-text table-sm table-hover table-dark">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>CPF</th>
                                <th>Nome</th>
                                <th>Fone 01</th>
                                <th>Fone 02</th>
                                <th>Email</th>
                                <th style={{ textAlign: 'center'}}>Status</th>
                                <th style={{ textAlign: 'center'}}>Admin</th>
                                <th style={{ textAlign: 'center'}}>Gestor</th>
                                <th style={{ textAlign: 'right'}}>Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {                     
                                listagem.map(usuario => (
                                    <tr key={usuario.USU_CODIGO}>
                                        <td>{usuario.USU_CODIGO}</td>
                                        <td>{usuario.USU_CPF}</td>
                                        <td>{usuario.USU_NOME}</td>
                                        <td>{usuario.USU_FONE1}</td>
                                        <td>{usuario.USU_FONE2}</td>
                                        <td>{usuario.USU_EMAIL}</td>
                                        <td align="center"><InputSwitch checked={usuario.USU_STATUS==="A"} onChange={(e) => setStatus(e,usuario.USU_CODIGO)} /></td>
                                        <td align="center"><InputSwitch checked={usuario.USU_ADMIN==="S"} onChange={(e) => setAdmin(e,usuario.USU_CODIGO)} /></td>
                                        <td align="center"><InputSwitch checked={usuario.USU_GESTOR==="S"} onChange={(e) => setGestor(e,usuario.USU_CODIGO)} /></td>
                                        <td align="right">
                                            <Link to="" onClick={() => editar(usuario.USU_CODIGO)} title="Editar"><i className="fa fa-fw fa-pencil"></i></Link>
                                            <Link to="" onClick={() => excluir({ 'codigo': usuario.USU_CODIGO, 'nome': usuario.USU_NOME })} title="Excluir"><i className="fa fa-fw fa-trash"></i></Link>
                                        </td>
                                    </tr>
                                ))
                            }   
                        </tbody>
                    </table>
                </IfComponent>
            </IfComponent>
    
            <div className="container limit-width bg-transparent pb-5" ref={scrollObserver} id="sentinela"></div>
            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-city"></i></span><br/>Nenhum usuário encontrado!</p>
                </div>                    
            </IfComponent>

        </ColunaCentral>
        <Rodape />
    </>
}

export default Usuarios;