import React from 'react';
//import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// funcoes proprias.
import BarraSuperior from '../componentes/BarraSuperior';
import Bloco from '../componentes/Bloco';
import ColunaCentral from '../componentes/ColunaCentral';
import Rodape from '../componentes/Rodape';

function Pagina() {
    const { id } = useParams();

    return <>
        <BarraSuperior />
        <ColunaCentral left="false" right="false">
            {/* <section dangerouslySetInnerHTML={{ __html: bloco }} /> */}
            <Bloco id={ id } pagina="S" titulo={true} texto=""/>
        </ColunaCentral>
        <Rodape />
    </>
}

export default Pagina; 